import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import AutomaticReportContainer from './styles';
import { Tabs, Radio, Select, Button, Table } from 'antd';
import {
    fetchAutomaticReportList,
    thunksDeleteAutomaticReportItem,
    thunksPostAutomaticReportItem,
} from 'services/AutomaticReport/thunks';
import { getEmailsByBranchId } from 'services/Config/thunks';
import { getVehicles } from 'services/Vehicle/thunks';
import {
    selectAutoReportsList,
    selectorsDeleteAutomaticReportItem,
    selectorsPostAutomaticReportItem,
} from 'services/AutomaticReport/selectors';
import { selectEmailsRecords } from 'services/Config/selectors';
import { selectVehicles } from 'services/Vehicle/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { parseJWT } from 'helpers';

const AutomaticReports = (props) => {
    const { intl } = props;
    const jwt = parseJWT(props.user.get('token'));
    const branchId = jwt['https://geothentic.com/branch_id'];
    const [currentReport, setCurrentReport] = useState(null);
    const [currentReports, setCurrentReports] = useState([]);
    const [currentEmail, setCurrentEmail] = useState(null);
    const [currentEmails, setCurrentEmails] = useState([]);
    const [currentVehicles, setCurrentVehicles] = useState([]);
    const [currentReportType, setCurrentReportType] = useState(null);
    const [currentReportPeriod, setCurrentReportPeriod] = useState(null);
    const [currentReportHour, setCurrentReportHour] = useState(null);
    const [currentReportFormat, setCurrentReportFormat] = useState(null);
    const [myEmailList, setMyEmailList] = useState([]);
    const [myVehicleList, setMyVehicleList] = useState([]);
    const [tableDataReportList, setTableDataReportList] = useState([]);
    const [loading, setLoading] = useState(false);

    const reportType = [
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportMove',
            }),
            value: '0',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportIdle',
            }),
            value: '1',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportSpeed',
            }),
            value: '2',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportUnauthorized',
            }),
            value: '3',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportStopTime',
            }),
            value: '4',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportAux',
            }),
            value: '5',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportInactivity',
            }),
            value: '9',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.reportHistoryGeofencing',
            }),
            value: '11',
        },
    ];

    const reportPeriod = [
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.weekly',
            }),
            value: '0',
        },
        {
            label: intl.formatMessage({
                id: 'automaticReport.list.table.daily',
            }),
            value: '1',
        },
    ];

    const reportHour = [
        {
            label: '24',
            value: '24',
        },
        {
            label: '12',
            value: '12',
        },
    ];

    const reportFormat = [
        {
            label: 'CSV',
            value: '1',
        },
        {
            label: 'PDF',
            value: '2',
        },
        {
            label: 'Excel',
            value: '3',
        },
    ];

    const tableColumnsReportList = [
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.vehicles',
            }),

            dataIndex: 'vehicleName',

            render: (text) => <a>{text}</a>,
        },
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.reportType',
            }),

            dataIndex: 'reportType',
        },
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.reportPeriod',
            }),

            dataIndex: 'reportPeriod',
        },
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.reportFormat',
            }),

            dataIndex: 'reportFormat',
        },
    ];

    const tableColumnsReportFormVehicles = [
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.vehicles',
            }),

            dataIndex: 'vehicleName',

            render: (text) => <a>{text}</a>,
        },
    ];

    const tableColumnsReportFormEmails = [
        {
            title: intl.formatMessage({
                id: 'automaticReport.list.table.emails',
            }),

            dataIndex: 'userEmail',

            render: (text) => <a>{text}</a>,
        },
    ];

    useEffect(
        () => {
            if (currentEmail) {
                props.fetchAutomaticReportList(currentEmail);
            }
        },
        [currentEmail, currentReports]
    );

    useEffect(() => {
        props.getEmailsByBranchId(branchId);
    }, []);

    useEffect(() => {
        props.getVehicles();
    }, []);

    const getReportTypeLabel = (value) => {
        const report = reportType.find((item) => item.value === value);
        return report ? report.label : 'Unknown';
    };

    const getReportFormatLabel = (value) => {
        const format = reportFormat.find((item) => item.value === value);
        return format ? format.label : 'Unknown';
    };

    const getReportPeriodLabel = (value) => {
        const period = reportPeriod.find((item) => item.value === value);
        return period ? period.label : 'Unknown';
    };

    const getReportHourLabel = (value) => {
        const hour = reportHour.find((item) => item.value === value);
        return hour ? hour.label : 'Unknown';
    };

    useEffect(
        () => {
            if (props.vehicleList && typeof props.vehicleList === 'object') {
                const vehicleListData =
                    props.vehicleList.toJS && typeof props.vehicleList.toJS === 'function'
                        ? props.vehicleList.toJS()
                        : props.vehicleList;

                const data = Array.isArray(vehicleListData)
                    ? vehicleListData
                          .map((vehicle) => ({
                              key: vehicle.id.toString(),
                              vehicleName: vehicle.name,
                          }))
                          .sort((a, b) => a.vehicleName.localeCompare(b.vehicleName))
                    : Object.values(vehicleListData)
                          .map((vehicle) => ({
                              key: vehicle.id.toString(),
                              vehicleName: vehicle.name,
                          }))
                          .sort((a, b) => a.vehicleName.localeCompare(b.vehicleName));

                setMyVehicleList(data);
            }
        },
        [props.vehicleList]
    );

    useEffect(
        () => {
            if (props.autoReportsList && Array.isArray(props.autoReportsList)) {
                const data = props.autoReportsList
                    .map((report, index) => {
                        const vehicle = myVehicleList.find(
                            (vehicle) => vehicle.key === report.vehicle_id.toString()
                        );

                        return {
                            key: report.id.toString(),
                            reportVehicle: report.vehicle_id,
                            vehicleName: vehicle ? vehicle.vehicleName : '',
                            reportType: getReportTypeLabel(report.report_type.toString()),
                            reportFormat: getReportFormatLabel(report.report_format.toString()),
                            reportPeriod: getReportPeriodLabel(report.report_frequence.toString()),
                            reportHour: getReportHourLabel(report.hour.toString()),
                        };
                    })
                    .sort((a, b) => a.vehicleName.localeCompare(b.vehicleName));

                setTableDataReportList(data);
            }
        },
        [props.autoReportsList, myVehicleList]
    );

    useEffect(
        () => {
            if (props.emailList && typeof props.emailList === 'object') {
                const emailListData =
                    props.emailList.toJS && typeof props.emailList.toJS === 'function'
                        ? props.emailList.toJS()
                        : props.emailList;

                const data = Array.isArray(emailListData)
                    ? emailListData
                          .map((user) => ({
                              key: user.id.toString(),
                              userEmail: user.emailAddress,
                          }))
                          .sort((a, b) => a.userEmail.localeCompare(b.userEmail))
                    : Object.values(emailListData)
                          .map((user) => ({
                              key: user.id.toString(),
                              userEmail: user.emailAddress,
                          }))
                          .sort((a, b) => a.userEmail.localeCompare(b.userEmail));

                setMyEmailList(data);
            }
        },
        [props.emailList]
    );

    const handleChangeReportType = (value) => {
        setCurrentReportType(value);
    };

    const handleChangeReportPeriod = (value) => {
        setCurrentReportPeriod(value);
    };

    const handleChangeReportHour = (value) => {
        setCurrentReportHour(value);
    };

    const handleChangeReportFormat = (e) => {
        setCurrentReportFormat(e.target.value);
    };

    const handleChangeVehicle = (value) => {
        setCurrentVehicles(value);
    };

    const handleChangeEmail = (e) => {
        setCurrentEmail(e.target.value);
    };

    const handleChangeEmails = (value) => {
        setCurrentEmails(value);
    };

    const handleDeleteAutomaticReportItem = async () => {
        if (currentReports.length > 0) {
            setLoading(true);

            for (const reportId of currentReports) {
                await props.thunksDeleteAutomaticReportItem(reportId);
            }

            await props.fetchAutomaticReportList(currentEmail);

            setCurrentReports([]);
            setLoading(false);
        }
    };

    const handlePostAutomaticReportItem = async () => {
        setLoading(true);

        const report = {
            vehicleIds: {
                vehicleId: currentVehicles.map((vehicle) => Number(vehicle)),
            },
            emailId: Number(currentEmail),
            reportType: Number(currentReportType),
            reportPeriod: Number(currentReportPeriod),
            reportHour: Number(currentReportHour),
            reportFormat: Number(currentReportFormat),
        };

        for (const emailId of currentEmails) {
            const reportWithEmail = {
                ...report,
                emailId: Number(emailId),
            };

            await props.thunksPostAutomaticReportItem(reportWithEmail);
        }

        setCurrentReportType(null);
        setCurrentReportPeriod(null);
        setCurrentReportHour(null);
        setCurrentReportFormat(null);
        setCurrentVehicles([]);
        setCurrentEmails([]);

        setLoading(false);
    };

    const rowSelectionReportList = {
        type: 'checkbox',
        selectedRowKeys: currentReports,
        onChange: (selectedKeys) => {
            setCurrentReports(selectedKeys);
        },
    };

    const rowSelectionReportFormVehicles = {
        type: 'checkbox',
        selectedRowKeys: currentVehicles,
        onChange: (selectedKeys) => {
            setCurrentVehicles(selectedKeys);
        },
    };

    const rowSelectionReportFormEmails = {
        type: 'checkbox',
        selectedRowKeys: currentEmails,
        onChange: (selectedKeys) => {
            setCurrentEmails(selectedKeys);
        },
    };

    const ReportForm = () => {
        return (
            <div>
                {/* Report Type */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.form.reportType'} />
                </div>
                <Select
                    defaultValue={currentReportType}
                    style={{
                        width: '100%',
                    }}
                    onChange={handleChangeReportType}
                    options={reportType}
                />
                <br />
                <br />

                {/* Report Period */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.form.reportPeriod'} />
                </div>
                <Select
                    defaultValue={currentReportPeriod}
                    style={{
                        width: '100%',
                    }}
                    onChange={handleChangeReportPeriod}
                    options={reportPeriod}
                />
                <br />
                <br />

                {/* Report Format */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.form.reportFormat'} />
                </div>
                <Radio.Group onChange={handleChangeReportFormat} value={currentReportFormat}>
                    {reportFormat.map((option) => (
                        <Radio key={option.value} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
                <br />
                <br />
                {/* Report Vehicles */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.form.reportVehicles'} />
                </div>
                <br />
                <Table
                    rowSelection={rowSelectionReportFormVehicles}
                    columns={tableColumnsReportFormVehicles}
                    dataSource={myVehicleList}
                    pagination={false}
                    scroll={{ y: 240 }}
                />
                <br />
                <br />
                {/* Report Emails */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.form.reportEmails'} />
                </div>
                <br />
                <Table
                    rowSelection={rowSelectionReportFormEmails}
                    columns={tableColumnsReportFormEmails}
                    dataSource={myEmailList}
                    pagination={false}
                    scroll={{ y: 240 }}
                />
                <br />
                <br />
                {/* Submit Button */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        loading={loading}
                        type="primary"
                        size="large"
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: 'green',
                            borderColor: 'green',
                        }}
                        onClick={handlePostAutomaticReportItem}
                        disabled={
                            currentEmails.length === 0 ||
                            currentVehicles.length === 0 ||
                            !currentReportType ||
                            !currentReportPeriod ||
                            !currentReportFormat
                        }
                    >
                        {intl.formatMessage({
                            id: 'automaticReport.form.button.submit',
                        })}
                    </Button>
                </div>
            </div>
        );
    };

    const ReportList = () => {
        return (
            <div>
                {/* Email List */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.list.reportEmails'} />
                </div>
                <br />
                <div
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginBottom: '15px',
                    }}
                >
                    <Radio.Group
                        options={myEmailList.map((user) => ({
                            label: `${user.userEmail}`,
                            value: user.key,
                        }))}
                        value={currentEmail}
                        onChange={handleChangeEmail}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    />
                </div>
                <br />
                <br />
                {/* Report List */}
                <div className={'title'}>
                    <FormattedMessage id={'automaticReport.list.reportReports'} />
                </div>
                <br />
                <Table
                    rowSelection={rowSelectionReportList}
                    columns={tableColumnsReportList}
                    dataSource={tableDataReportList}
                    pagination={false}
                    scroll={{ y: 240 }}
                />

                {/* Delete Button */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: 'green',
                            borderColor: 'green',
                        }}
                        onClick={handleDeleteAutomaticReportItem}
                        disabled={currentReports.length === 0}
                        loading={loading}
                    >
                        {intl.formatMessage({
                            id: 'automaticReport.list.button',
                        })}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <AutomaticReportContainer>
            <AutomaticReportContainer.Header>
                {intl.formatMessage({
                    id: 'automaticReport',
                })}
            </AutomaticReportContainer.Header>
            <hr />
            <div>
                <AutomaticReportContainer.TabsContainer>
                    <Tabs
                        defaultActiveKey="1"
                        size="large"
                        tabPosition="top"
                        style={{
                            marginBottom: 25,
                            width: '100%',
                        }}
                        onChange={(key) => {
                            setCurrentEmail(null);
                            setCurrentEmails([]);
                            setTableDataReportList([]);
                            setCurrentReportType(null);
                            setCurrentReportPeriod(null);
                            setCurrentReportHour(null);
                            setCurrentReportFormat(null);
                            setCurrentVehicles([]);
                        }}
                        items={[
                            {
                                label: intl.formatMessage({
                                    id: 'automaticReport.form.title',
                                }),
                                key: '1',
                                children: <ReportForm />,
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'automaticReport.list.title',
                                }),
                                key: '2',
                                children: <ReportList />,
                            },
                        ]}
                    />
                </AutomaticReportContainer.TabsContainer>
            </div>
        </AutomaticReportContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    autoReportsList: selectAutoReportsList(),
    emailList: selectEmailsRecords(),
    user: selectUser(),
    deleteAutomaticReportItem: selectorsDeleteAutomaticReportItem(),
    postAutomaticReportItem: selectorsPostAutomaticReportItem(),
    vehicleList: selectVehicles(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAutomaticReportList,
            getEmailsByBranchId,
            thunksDeleteAutomaticReportItem,
            getVehicles,
            thunksPostAutomaticReportItem,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(AutomaticReports))
);
