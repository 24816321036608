// @flow

// IMPORTS
import dateFns from 'date-fns';

// THUNKS
import { setSelectedVehicles } from 'services/SideMenu/thunks';
import { renewUserSession } from 'services/Authentication/thunks';
import moment from 'moment';
import 'moment-timezone';

// ACTIONS
import {
    receivedVehiclesSuccess,
    receivedVehiclesEventTypes,
    receivedVehicleHistorySuccess,
    receivedToggleShowVehicleHistory,
    receivedMapPopupEquipment,
    receivedSetFilter,
    receivedEquipmentHistoryError,
    receivedEmptyHistory,
    receivedVehicleDataError,
    receivedDissmissedMapError,
    receivedDissmissedMapEmptyHistory,
    receivedVehiclesError,
    startLoading,
    startLoadingVehicle,
    startLoadingVehicleHistory,
    startLoadingEquipmentPopup,
    receivedEventGroupsSuccess,
    receivedClearMapPopupEquipment,
    receivedClearVehicleHistory,
    receivedClearVehicles,
    receivedMaskedPoints,
    receivedRouteMatching,
    receivedVehiclesSortOrder,
    receivedVehicleExternalWebHookSuccess,
    receivedChangeWebHkSuccess,
    receivedChangeWebHkError,
    receivedVehicleHistoryDetailSuccess,
    receivedFleetEquipmentSuccess,
    receivedFleetEquipmentError,
    receivedClearVehicleHistoryDetail,
    receivedClearFleetEquipment,
    receivedToggleVehicleHistory,
    setVehicles,
    stopLoading,
    receivedControlColors,
    receivedGarageMode,
} from './actions';

// RESOURCES
import {
    retrieveVehicles,
    retrieveEventTypes,
    retrieveEquipmentHistory,
    mapboxMapMatching,
    mapboxGeoToAddress,
    mapboxDirections,
    retrieveVehicle,
    retrieveExternalVehicle,
    getVehicleAdmin,
    changeWebHookState,
    editWebHookByEquipementId,
    retrieveVehicleHistoryDetail,
    retrieveFleetEquipmentById,
    updateHorometerOdometer,
    retrieveVehicleDetail,
    retrieveVehicleById,
    retrieveInternalAPIVehicles,
    editGarageModeIcons,
    requestDriverCall,
    cancelRequestDriverCall,
    requestStopAntitheftAlarm,
    getDoVehicleExistsInProgression,
} from './resources';

import { receivedCloseModal } from '../Modal/actions';

// TYPES
import type { ImmutableMap } from 'types';

// SERVICES
import auth0 from 'services/Authentication/auth0';
import { MAPBOX_GL_TOKEN } from '../../env';
import { retrieveAdminVehicle } from '../socket/resources';
import { setIsMultipleSelect } from 'services/SideMenu/thunks';
import { parseJWT } from '../../helpers';
import { addCategorie } from '../Categorie/resources';
import { receivedCategorieError } from '../Categorie/actions';
import { getCategoriesByBranch } from '../Categorie/thunks';

/**
 * Fetch vehicle data for current user.
 * @param {*} sort
 * @param {*} order
 */
export const getVehicles = (moduleTypes = '', page = 1) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const expiresAt = getState().getIn(['auth', 'user', 'expiresAt']);

    if (auth0.willExpireSoon(expiresAt)) {
        if (expiresAt == sessionStorage.expires_at) {
            auth0.renewSession();
        }
        dispatch(renewUserSession());
    }
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveVehicles(
        token,
        locale,
        getState().getIn(['vehicle', 'sort']),
        getState().getIn(['vehicle', 'order']),
        moduleTypes,
        page
    )
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedVehiclesError());
            }
            if (response.links && response.links.filter((link) => link.rel === 'next').length > 0) {
                getVehicles(moduleTypes, page + 1)(dispatch, getState);
            }
                response.data.forEach((vehicle) => {

                    if (vehicle.additionalData.Bluetooth) {
                    
                                            vehicle.latestRealTime = {
                                                latitudeWgs84: vehicle.additionalData.Bluetooth.last_latitude,
                                                longitudeWgs84: vehicle.additionalData.Bluetooth.last_longitude
                                            };
                    
                                            vehicle.status = {
                                                "eventTypeId": 5,
                                                "description": "KEY_ON",
                                                "frenchDescription": "Démarrage",
                                                "englishDescription": "Start",
                                                "title": "Périodique",
                                                "status": "active"
                                            }     
                                        }

                          
                })
            dispatch(receivedVehiclesSuccess(response.data, false));
        })
        .catch((error) => {
            if (error) dispatch(receivedVehiclesError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} coordinate
 * @param {*} history
 */
export const getAdminVehicle = (vehicle, coordinate: ?Coordinates, history: ?boolean) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(receivedCloseModal());
    dispatch(startLoadingVehicle());
    let vehicle2;
    if (vehicle && vehicle.get(0) && vehicle.get(0).get('id')) {
        vehicle = vehicle.get(0);
    }
    vehicle2 = Object.fromEntries(vehicle);

    if (vehicle.get('since')) {
        vehicle2.since = vehicle
            .get('since')
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, '/')
            .replace('T', ' ');
    }

    let coords = { longitude: null, latitude: null };
    if (history) {
        coords = {
            longitude: Number(vehicle2.longitudeWgs84),
            latitude: Number(vehicle2.latitudeWgs84),
        };
    } else if (vehicle2.targetLocation) {
        coords = {
            latitude: Number(vehicle2.targetLocation.latitudeWgs84),
            longitude: Number(vehicle2.targetLocation.longitudeWgs84),
        };
    } else if (vehicle2.latestRealTime) {
        coords = {
            latitude: Number(vehicle2.latestRealTime.latitudeWgs84),
            longitude: Number(vehicle2.latestRealTime.longitudeWgs84),
        };
    }

    dispatch(receivedMapPopupEquipment([vehicle2], coords, history));
};

export const stopAntiTheftAlarm = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);

    return requestStopAntitheftAlarm(token, id).then((response: Object) => {});
};

export const callDriver = (id: number) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);

    return requestDriverCall(token, id)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                // dispatch(receivedCategorieError());
            }
            // dispatch(getCategoriesByBranch());
            //  return response.data
        })
        .catch((error) => {
            //if (error) return dispatch(receivedVehiclesError());
        });
};

export const cancelCallDriver = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);

    return cancelRequestDriverCall(token, id)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                // dispatch(receivedCategorieError());
            }
            // dispatch(getCategoriesByBranch());
            //  return response.data
        })
        .catch((error) => {
            //if (error) return dispatch(receivedVehiclesError());
        });
};

export const getVehicle = (id: number, coordinate: ?Coordinates, history: ?boolean) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    dispatch(startLoadingVehicle());

    return retrieveVehicle(token, locale, id)
        .then((response: Object) => {
            const vehicle: VehicleType = response.data.data;

            let coords = [];
            if (history) {
                coords = {
                    longitude: Number(vehicle.longitudeWgs84),
                    latitude: Number(vehicle.latitudeWgs84),
                };
            } else if (vehicle.targetLocation) {
                coords = {
                    latitude: Number(vehicle.targetLocation.latitudeWgs84),
                    longitude: Number(vehicle.targetLocation.longitudeWgs84),
                };
            } else if (vehicle.latestRealTime) {
                coords = {
                    latitude: Number(vehicle.latestRealTime.latitudeWgs84),
                    longitude: Number(vehicle.latestRealTime.longitudeWgs84),
                };
            } else {
                coords = {
                    latitude: null,
                    longitude: null,
                };
            }

            return mapboxGeoToAddress(coords.latitude, coords.longitude).then((locations) => {
                let address = null;

                if (locations && locations.data && locations.data.features) {
                    address = {
                        streetAddress: `${(locations.data.features[0] &&
                            locations.data.features[0].address) ||
                            ''} ${(locations.data.features[0] && locations.data.features[0].text) ||
                            ''}`,
                        city: `${locations.data.features[2] && locations.data.features[2].text}`,
                        province: `${(locations.data.features[3] &&
                            locations.data.features[3].text) ||
                            ''}`,
                        country: `${(locations.data.features[4] &&
                            locations.data.features[4].text) ||
                            ''}`,
                    };
                }

                vehicle.address = address;
                dispatch(receivedMapPopupEquipment([vehicle], coords, history));
            });
        })
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};

export const getExternalVehicle = (id: number, source: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    dispatch(startLoadingVehicle());

    return retrieveExternalVehicle(token, locale, id, source)
        .then((response: Object) => {
            const vehicle: VehicleType = response.data.data;
            // Default values
            let coords = {
                latitude: 0,
                longitude: 0,
            };
            // Modify coords if module is installed
            if (vehicle.latestRealTime !== null)
                coords = {
                    latitude: Number(vehicle.latestRealTime.latitudeWgs84),
                    longitude: Number(vehicle.latestRealTime.longitudeWgs84),
                };
            dispatch(receivedMapPopupEquipment([vehicle], coords));
        })
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};

export const getVehicleHistoryDetail = (
    id: number,
    startDate: string,
    endDate: string,
    pageNumber: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    // dispatch(startLoadingVehicle());

    return retrieveVehicleHistoryDetail(token, id, startDate, endDate, pageNumber)
        .then((response: Object) => {
            dispatch(receivedVehicleHistoryDetailSuccess(response.data.Events));
        })
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};

export const getFleetEquipment = (
    serialID: number,
    startDate: string,
    endDate: string,
    pageNumber: number,
    isTempReport: boolean
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    console.log('we use it');
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    // dispatch(startLoadingVehicle());

    return retrieveFleetEquipmentById(token, serialID, startDate, endDate, pageNumber, isTempReport)
        .then((response: Object) => {
            dispatch(
                receivedFleetEquipmentSuccess(
                    response.data.location,
                    response.data.nextPage,
                    isTempReport
                )
            );
        })
        .catch((error) => {
            if (error) return dispatch(receivedFleetEquipmentError(error));
        });
};

/**
 *
 * @param {*} equipmentId
 * @param {*} date
 */
export const getVehicleHistoryMarker = (equipmentId, date) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    dispatch(startLoadingEquipmentPopup());
    const mtlTime = new Date(date).toLocaleString('en-US', { timeZone: 'America/Montreal' });
    const myDate = moment(new Date(mtlTime).toString()).format('YYYY-MM-DD HH:mm:ss');
    const timePeriod = myDate.split(' '); // 0 = date, 1 = time
    return retrieveVehicle(token, locale, equipmentId)
        .then((response: Object) => {
            const vehicle = response.data.data;
            return retrieveEquipmentHistory(
                equipmentId,
                token,
                locale,
                timePeriod[0],
                timePeriod[0],
                timePeriod[1],
                timePeriod[1]
            ).then((results) => {
                if (results.data.data.length > 0) {
                    const latest = results.data.data[0];
                    // format time for popout display
                    const timeMoment = moment(latest.datetime).tz('America/Montreal');
                    const timeFormatted = timeMoment
                        .local()
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss');
                    vehicle.since = timeFormatted;
                    vehicle.status.eventTypeId = latest.eventTypeId;
                    vehicle.latestRealTime.datetime = latest.datetime;
                    vehicle.latestRealTime.distance = latest.distance;
                    vehicle.latestRealTime.eventTypeId = latest.eventTypeId;
                    vehicle.latestRealTime.heading = latest.heading;
                    vehicle.latestRealTime.latitudeWgs84 = latest.latitudeWgs84;
                    vehicle.latestRealTime.longitudeWgs84 = latest.longitudeWgs84;
                    vehicle.latestRealTime.operator = latest.operator;
                    vehicle.latestRealTime.realtimeId = latest.realtimeId;
                    vehicle.latestRealTime.sensor_key = latest.sensorKey;
                    vehicle.latestRealTime.speed = latest.speed;
                    vehicle.latestRealTime.isMarker = true;
                    vehicle.latestRealTime.spreaderData = latest.spreaderData;
                    vehicle.latestRealTime.sensorAux = latest.sensorAux;
                    vehicle.latestRealTime.sensorAux2 = latest.sensorAux2;
                    vehicle.latestRealTime.sensorAux3 = latest.sensorAux3;
                    vehicle.latestRealTime.sensorAux4 = latest.sensorAux4;

                    const coordinate = {
                        longitude: Number(latest.longitudeWgs84),
                        latitude: Number(latest.latitudeWgs84),
                    };

                    return mapboxGeoToAddress(coordinate.latitude, coordinate.longitude).then(
                        (locations) => {
                            let address = null;

                            if (locations && locations.data && locations.data.features) {
                                address = {
                                    streetAddress: `${(locations.data.features[0] &&
                                        locations.data.features[0].address) ||
                                        ''} ${(locations.data.features[0] &&
                                        locations.data.features[0].text) ||
                                        ''}`,
                                    city: `${(locations.data.features[2] &&
                                        locations.data.features[2].text) ||
                                        ''}`,
                                    province: `${(locations.data.features[3] &&
                                        locations.data.features[3].text) ||
                                        ''}`,
                                    country: `${(locations.data.features[4] &&
                                        locations.data.features[4].text) ||
                                        ''}`,
                                };
                            }

                            vehicle.latestRealTime.address = address;

                            return dispatch(receivedMapPopupEquipment([vehicle], coordinate, true));
                        }
                    );
                } else {
                    return dispatch(receivedMapPopupEquipment([vehicle], [], true));
                }
            });
        })
        .catch((error) => {
            console.log('ERROR - getVehicleHistoryMarker');
            if (error) dispatch(receivedEquipmentHistoryError(error));
        });
};

/**
 *
 * @param {*} index
 * @param {*} vehicles
 * @param {*} coordinate
 * @param {*} history
 */
export const updateVehicle = (
    index: number,
    vehicles: ?Array<VehicleType>,
    coordinate: ?Coordinates,
    history: ?boolean
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    dispatch(startLoading());

    return retrieveVehicle(token, locale, vehicles.get(index.toString()).get('id'))
        .then((response: Object) => {
            const vehicle: VehicleType = response.data.data;
            dispatch(
                receivedMapPopupEquipment(
                    [...vehicles.slice(0, index), vehicle, ...vehicles.slice(index + 1)],
                    coordinate,
                    history,
                    index
                )
            );
        })
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};

// @TODO: Consider removing or refactor?
// export const mapEquipmentPopout = (
//     vehicles: ?Array<VehicleType>,
//     coordinate: ?Coordinates,
//     history: ?boolean
// ) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
//     const token = getState().getIn(['auth', 'user', 'token']);
//     const locale = getState().getIn(['language', 'locale']);

//     dispatch(startLoading());

//     return retrieveVehicle(token, locale, vehicles[0].get('id'))
//         .then((response: Object) => {
//             const vehicle: VehicleType = response.data.data;
//             dispatch(receivedMapPopupEquipment([vehicle, ...vehicles.slice(1)], coordinate, history));
//         })
//         .catch((error) => {
//             return dispatch(receivedVehiclesError());
//         });
// }

/**
 *
 */
export const getEventTypes = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return retrieveEventTypes(token, locale)
        .then((response: Object) => {
            dispatch(receivedVehiclesEventTypes(response.data));
        })
        .catch((error) => {
            // dispatch(receivedAuthenticationFail(error));
        });
};

/**
 *
 * @param {*} equipmentId
 */
export const toggleShowVehicleHistory = (equipmentId: number) => (dispatch: ReduxDispatch) =>
    dispatch(receivedToggleShowVehicleHistory(equipmentId));

/**
 * Cleanup raw coordiantes & Remove duplicates and outliers
 * @param {*} vehicleGeoData
 */
export const cleanupCoordinates = (vehicleGeoData) => {
    const cleanedVehicleGeoData = [];

    // let prevCoordinate = null;
    vehicleGeoData.forEach((element) => {
        // Add a confidence level to point based on speed
        let confidence = 0;
        if (element.speed <= 10) {
            confidence = 0;
        } else if (element.speed <= 20) {
            confidence = 1;
        } else if (element.speed <= 30) {
            confidence = 2;
        } else if (element.speed <= 40) {
            confidence = 3;
        } else if (element.speed <= 50) {
            confidence = 4;
        } else {
            confidence = 5;
        }
        // eslint-disable-next-line no-param-reassign
        element.confidence = confidence;

        cleanedVehicleGeoData.push(element);
    });

    return cleanedVehicleGeoData;
};

/**
 * Matches raw coordinates to road
 * @param {*} vehicleGeoData
 */
export const matchCoordinates = async (vehicleGeoData) => {
    // MapBox can only cleanup 100 coordinates at a time
    // Trying to find the best chunk size for mor eprecise results
    const vehicleGeoDataChunks = [];
    const chunkSize = 75; // Size of chunk

    // There needs to be a little overlap in chunks to display properly
    const overlapSize = 1; // Size of overlap between chunks

    // If list is smaller or equal to chunkSize, we just take it all
    if (vehicleGeoData.length <= chunkSize) {
        vehicleGeoDataChunks.push(vehicleGeoData);
    } else {
        let startPos = 0;
        let endPos = startPos + chunkSize;
        while (startPos < vehicleGeoData.length) {
            // Just to make sure we never get a route with a single position
            if (vehicleGeoData.length - startPos === 1) {
                startPos = startPos - 1;
            }
            vehicleGeoDataChunks.push(vehicleGeoData.slice(startPos, endPos));
            startPos = endPos - overlapSize;
            endPos = startPos + chunkSize;
        }
    }

    // Loop on chunks and call MapBox Matching API
    const mapboxResponsePromises = [];
    vehicleGeoDataChunks.forEach((vehicleGeoDataChunk) => {
        const mapboxResponsePromise = mapboxMapMatching(vehicleGeoDataChunk);
        if (mapboxResponsePromise) {
            mapboxResponsePromises.push(mapboxResponsePromise);
        }
    });

    // Combine all the MapBox Responses in one array
    let mapboxResponses = [];
    if (mapboxResponsePromises.length > 0) {
        mapboxResponses = await Promise.all(mapboxResponsePromises).then((reponse) => reponse);
    }

    let waypoints = [];

    // We've made multiple calls to mapbox
    // So we combine results here
    if (mapboxResponses.length > 0) {
        mapboxResponses.forEach((element) => {
            // If more than one matched routes
            // Use the one with highest confidence level
            if (
                element &&
                element.data &&
                element.data.matchings &&
                element.data.matchings.length > 0
            ) {
                let confidence = 0;
                let mapboxCoords = null;

                element.data.matchings.forEach((matching) => {
                    if (matching.confidence >= confidence) {
                        confidence = matching.confidence;
                        mapboxCoords = matching.geometry.coordinates;
                    }
                });

                // const mapboxCoords = element.data.matchings[0].geometry.coordinates;
                waypoints = waypoints.concat(mapboxCoords);
            }
        });
    }

    return waypoints;
};

/**
 *
 * @param {*} latitude
 * @param {*} longitude
 */
export const matchCoordToAddress = async (latitude, longitude) => {
    const results = await mapboxGeoToAddress(latitude, longitude);
    return results;
};

/**
 * Matches raw coordinates to road
 * @param {*} coordinates
 */
export const getDirectionsCoordinates = async (coordinates, isOverview) => {
    // MapBox can only map 25 coordinates at a time
    // So we split them in chunks of 25
    const coordinatesChunks = [];
    const chunkSize = 25; // Size of chunk

    // There needs to be a little overlap in chunks to display properly
    // const overlapSize = 5; // Size of overlap between chunks
    const overlapSize = 0; // The way markers are displayed breaks at the moment with overlap

    // If list is smaller or equal to chunkSize, we just take it all
    if (coordinates.length <= chunkSize) {
        coordinatesChunks.push(coordinates);
    } else {
        let startPos = 0;
        let endPos = startPos + chunkSize;
        while (startPos < coordinates.length) {
            coordinatesChunks.push(coordinates.slice(startPos, endPos));
            startPos = endPos - overlapSize;
            endPos = startPos + chunkSize;
        }
    }

    const mapboxResponsePromises = [];
    coordinatesChunks.forEach((chunk) => {
        const opts = mapboxDirections(chunk, isOverview);
        mapboxResponsePromises.push(opts);
    });

    // Combine all the MapBox Responses in one array
    const mapboxResponse = await Promise.all(mapboxResponsePromises).then((reponse) => reponse);

    let waypoints = [];

    // We've made multiple calls to mapbox
    // So we combine results here
    if (mapboxResponse.length > 0) {
        mapboxResponse.forEach((element) => {
            if (element.data.routes.length > 0) {
                const mapboxCoords = element.data.routes[0].geometry.coordinates;
                waypoints = waypoints.concat(mapboxCoords);
            }
        });
    }

    return waypoints;
};

// Make a Map Matching request
export async function getMatch(coordinates) {
    // Create the query
    const radii = coordinates.map((x) => 25).join(';');
    const coords = coordinates.join(';');
    const query =
        'https://api.mapbox.com/matching/v5/mapbox/driving/' +
        coords +
        '?geometries=geojson&overview=full&radiuses=' +
        radii +
        `&steps=false&access_token=${MAPBOX_GL_TOKEN}`;
    return fetch(query);
}

/**
 *
 * @param {*} equipmentIds
 * @param {*} periodDateFrom
 * @param {*} periodDateTo
 * @param {*} periodTimeFrom
 * @param {*} periodTimeTo
 */
export const getMultipleEquipmentHistory = (
    equipmentIds,
    periodDateFrom: string = dateFns.format(new Date(), 'YYYY-MM-DD'),
    periodDateTo: string = dateFns.format(new Date(), 'YYYY-MM-DD'),
    periodTimeFrom: string = '00:00',
    periodTimeTo: string = '23:59',
    pageNumber: integer = 1,
    multipleSelect: boolean = false
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    if (pageNumber == 1) {
        dispatch(startLoadingVehicleHistory());
        dispatch(receivedClearVehicleHistory());
    }
    dispatch(setIsMultipleSelect(multipleSelect));
    // Select if it's a single vehicle
    if (equipmentIds.length === 1 && pageNumber == 1) {
        dispatch(
            setSelectedVehicles({
                [equipmentIds[0]]: true,
            })
        );
    }
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const utcStart = new moment(`${periodDateFrom}T${periodTimeFrom}`, 'YYYY-MM-DDTHH:mm')
        .utc()
        .format();
    const utcEnd = new moment(`${periodDateTo}T${periodTimeTo}`, 'YYYY-MM-DDTHH:mm').utc().format();
    return Promise.all(
        equipmentIds.map((equipmentId) => {
            return retrieveVehicleHistoryDetail(
                token,
                equipmentId,
                utcStart,
                utcEnd,
                pageNumber,
                true
            )
                .then(async (history) => {
                    if (!history.data.Events.length && !history.data.rawLineString) {
                        return { historyCoordinates: [], mapboxResponse: [] };
                    }
                    dispatch(receivedVehicleHistoryDetailSuccess(history.data.Events));

                    // Extract vehicle history data
                    const historyCoordinates = history.data.Events.map((location) => {
                        let date = moment(location.timestamp)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss');

                        return {
                            coordinate: [location.longitude, location.latitude], // Those will be snapped to road
                            originalCoordinate: [location.longitude, location.latitude], // Those we keep original position
                            eventTypeId: location.eventTypeId,
                            heading: location.heading,
                            datetime: date,
                            speed: location.speedKilometers,
                            vehicle: location,
                        };
                    });

                    const waypoints = historyCoordinates.map((coord) => {
                        return coord.coordinate;
                    });

                    // Cleanup raw coordiantes
                    // const cleanedVehicleGeoData = cleanupCoordinates(vehicleGeoData);

                    // Get matched coordinates
                    // const waypoints = []; //await matchCoordinates(cleanedVehicleGeoData);

                    return {
                        historyCoordinates,
                        waypoints,
                        matchingLineString: history.data.matchingLineString,
                        rawLineString: history.data.rawLineString,
                        pageNumber: history.data.pageNumber,
                        hasNext: history.data.hasNext,
                    };
                })
                .then(
                    ({
                        historyCoordinates,
                        waypoints,
                        matchingLineString,
                        rawLineString,
                        pageNumber,
                        hasNext,
                    }) => {
                        // No error, but history is empty
                        if (!historyCoordinates.length && !rawLineString) {
                            dispatch(
                                receivedVehicleHistorySuccess(
                                    [],
                                    equipmentId,
                                    [],
                                    null,
                                    null,
                                    1,
                                    false
                                )
                            );
                            return false;
                        }
                        dispatch(
                            receivedVehicleHistorySuccess(
                                waypoints,
                                equipmentId,
                                historyCoordinates,
                                matchingLineString,
                                rawLineString,
                                pageNumber,
                                hasNext
                            )
                        );

                        return true;
                    }
                )
                .catch((error) => {
                    console.log('ERROR');
                    console.log(error);
                    if (error) dispatch(receivedEquipmentHistoryError(error));
                });
        })
    ).then((foundArray) => {
        // Checks if all promises failed
        if (foundArray.every((item) => !item)) {
            dispatch(
                receivedVehiclesError({
                    header: 'errors.MapError.NotFound.Header',
                    message: 'errors.MapError.NotFound.Message',
                })
            );
        }
    });
};

/**
 *
 * @param {*} equipmentId
 * @param {*} periodDateFrom
 * @param {*} periodDateTo
 * @param {*} periodTimeFrom
 * @param {*} periodTimeTo
 */
export const getEquipmentHistory = (
    equipmentId: number,
    periodDateFrom: string = '',
    periodDateTo: string = '',
    periodTimeFrom: string = '',
    periodTimeTo: string = ''
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(startLoadingEquipmentPopup());

    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    // eslint-disable-next-line flowtype-errors/show-errors
    return retrieveEquipmentHistory(
        equipmentId,
        token,
        locale,
        periodDateFrom,
        periodDateTo,
        periodTimeFrom,
        periodTimeTo
    )
        .then(async (history) => {
            if (!history.data.data.length) {
                return { historyCoordinates: [], mapboxResponse: [] };
            }

            // Extract vehicle history data
            const historyCoordinates = history.data.data.map((location) => {
                return {
                    coordinate: [location.longitudeWgs84, location.latitudeWgs84], // Those will be snapped to road
                    originalCoordinate: [location.longitudeWgs84, location.latitudeWgs84], // Those we keep original position
                    eventTypeId: location.eventTypeId,
                    heading: location.heading,
                    datetime: location.datetime,
                    speed: location.speed,
                    vehicle: location,
                };
            });

            const vehicleGeoData = historyCoordinates.map((coord) => {
                const date = new Date(coord.datetime);
                const timestamp = date.getTime();

                return {
                    coordinates: coord.coordinate,
                    timestamp,
                    speed: coord.speed,
                };
            });

            // Cleanup raw coordiantes
            const cleanedVehicleGeoData = cleanupCoordinates(vehicleGeoData);

            // Get matched coordinates
            const waypoints = await matchCoordinates(cleanedVehicleGeoData);

            return { historyCoordinates, waypoints };
        })
        .then(({ historyCoordinates, waypoints }) => {
            // No error, but history is empty
            // Just show a message and finish here
            if (!historyCoordinates.length) {
                dispatch(receivedEmptyHistory());
                dispatch(receivedVehicleHistorySuccess([], equipmentId, []));
                return new Promise((resolve, reject) => {
                    return resolve();
                });
            }

            dispatch(receivedVehicleHistorySuccess(waypoints, equipmentId, historyCoordinates));
            return new Promise((resolve, reject) => {
                return resolve();
            });
        })
        .catch((error) => {
            if (error) dispatch(receivedEquipmentHistoryError(error));
        });
};

/**
 *
 * @param {*} vehicles
 * @param {*} coordinate
 * @param {*} history
 */
export const mapEquipmentPopout = (
    vehicles: ?Array<VehicleType>,
    coordinate: ?Coordinates,
    history: ?boolean
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    dispatch(startLoadingEquipmentPopup());

    return retrieveVehicle(token, locale, vehicles.getIn(['0', 'id']))
        .then((response: Object) => {
            const vehicle: VehicleType = response.data.data;
            dispatch(
                receivedMapPopupEquipment([vehicle, ...vehicles.slice(1)], coordinate, history)
            );
        })
        .catch((error) => {
            if (error) dispatch(receivedVehiclesError());
        });
};

export const getVehicleExternalWebHook = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return getVehicleAdmin(id, token)
        .then((response) => {
            dispatch(receivedVehicleExternalWebHookSuccess(response));
        })
        .catch((error) => {
            dispatch(receivedVehicleError(error));
        });
};

export const doVehicleExistInProgression = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return getDoVehicleExistsInProgression(token, id)
        .then((response) => {
            if (response.status == 200) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            false;
        });
};

export const changeWebHkState = (
    isPDRChecked: boolean,
    isPROGRESSIONChecked: boolean,
    id: number,
    isChecked: boolean,
    modem_id: string,
    progressionVehName: string,
    isNSIMChecked: boolean,
    isMTQChecked: boolean
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        externalWebhooks: {
            PROGRESSION: { activated: isPROGRESSIONChecked, vehicle_numb: progressionVehName },
            V_MTL: { activated: isChecked, modem_id: modem_id },
            PDR: { activated: isPDRChecked },
            NSIM: { activated: isNSIMChecked },
            MTQ: { activated: isMTQChecked },
        },
    };
    return changeWebHookState(id, token, data)
        .then((response) => {
            dispatch(receivedChangeWebHkSuccess(response));
            return true;
        })
        .catch((error) => {
            dispatch(receivedChangeWebHkError(error));
            return false;
        });
};

/**
 * Edit WebHook Data Config Equipment.
 */
export const editWebHookData = (equipementId: number, requestData: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    //dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editWebHookByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit WebHook Data Config Equipment.
 */
export const editControl = (branchID: number, requestData: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return editGarageModeIcons(token, branchID, requestData)
        .then((response) => {
            dispatch(receivedControlColors(JSON.parse(response.config.data).garageModeIcons));
        })
        .catch((error) => {
            return false;
        });
};

/**
 *
 */
export const setClearMapEquipmentPopout = () => (dispatch: ReduxDispatch, p) => {
    dispatch(receivedClearMapPopupEquipment());
};

/**
 *
 * @param {*} filterEvents
 */
export const setVehicleFilters = (filterEvents: Array<string>) => (dispatch: ReduxDispatch) =>
    dispatch(receivedSetFilter(filterEvents));

/**
 *
 * @param {*} filterEvents
 */
export const updateVehicleState = (vehicle) => (dispatch: ReduxDispatch) => {
    dispatch(setVehicles(vehicle));
};
/**
 *
 * @param {*} filterEvents
 */
export const dismissMapError = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedDissmissedMapError());
/**
 *
 * @param {*} filterEvents
 */
export const dismissMapEmptyHistory = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedDissmissedMapEmptyHistory());
/**
 *
 * @param {*} filterEvents
 */
export const vehicleDataError = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedVehicleDataError());
/**
 *
 * @param {*} filterEvents
 */
export const dispatchVehicleRequestError = (error) => (dispatch: ReduxDispatch) =>
    dispatch(receivedVehiclesError(error));
/**
 *
 * @param {*} filterEvents
 */
export const getEventGroups = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
};

/**
 *
 */
export const clearVehicleHistory = () => (dispatch) => {
    dispatch(receivedClearVehicleHistory());
};

/**
 *
 */
export const clearVehicles = () => (dispatch) => {
    dispatch(receivedClearVehicles());
};

export const toggleMaskedPoints = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedMaskedPoints());
};

export const toggleRouteMatching = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedRouteMatching());
};

export const toggleVehiclesSortOrder = (sort: string, order: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(receivedVehiclesSortOrder(sort, order));
};

export const clearVehicleHistoryDetail = (sort: string, order: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(receivedClearVehicleHistoryDetail(sort, order));
};

export const clearFleetEquipment = (sort: string, order: string) => (dispatch: ReduxDispatch) => {
    dispatch(receivedClearFleetEquipment(sort, order));
};

export const toggleVehicleHistory = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedToggleVehicleHistory());
};

export const startloading = () => (dispatch: ReduxDispatch) => {
    dispatch(startLoadingVehicleHistory());
};

export const stoploading = () => (dispatch: ReduxDispatch) => {
    dispatch(stopLoading());
};

export const setControlColor = (controlColor) => (dispatch: ReduxDispatch) => {
    dispatch(receivedControlColors(controlColor));
};

export const setGarageMode = (data) => (dispatch: ReduxDispatch) => {
    dispatch(receivedGarageMode(data));
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const patchHorometerOdometer = (id: number, data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return updateHorometerOdometer(token, locale, id, data)
        .then((response: Object) => {})
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};

/**
 *
 * @param {*} id
 *
 */
export const getVehicleById = (id: number) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveVehicleById(token, id)
        .then((response: Object) => {
            return response.data;
        })
        .catch((error) => {
            if (error) return dispatch(receivedVehiclesError());
        });
};
