// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const AutmaticReportContainer = styled.div`
    border-radius: 5px;
    border: 1px solid ${colors.green61};
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    height: 95%;
    margin: auto;
`;
AutmaticReportContainer.Header = styled.div`
    font-family: OpenSans-SemiBold;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 10px;
    color: #000000;
`;
AutmaticReportContainer.TabsContainer = styled.div`
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    height: 95%;
    margin: auto;
    justify-content: center;
    display: flex;
    max-width: 65%;
`;
AutmaticReportContainer.Input = styled.input`
    height: 100%;
    width: 100%;
    border: none;
    background: #f2f2f2;
    &:focus {
        outline: none;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-family: OpenSans;
`;

export default AutmaticReportContainer;
