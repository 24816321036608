export const FETCH_AUTOMATIC_REPORT_LIST_REQUEST =
    'app/services/AutomaticReport/FETCH_AUTOMATIC_REPORT_LIST_REQUEST';
export const FETCH_AUTOMATIC_REPORT_LIST_SUCCESS =
    'app/services/AutomaticReport/FETCH_AUTOMATIC_REPORT_LIST_SUCCESS';
export const FETCH_AUTOMATIC_REPORT_LIST_FAILURE =
    'app/services/AutomaticReport/FETCH_AUTOMATIC_REPORT_LIST_FAILURE';

export const DELETE_AUTOMATIC_REPORT_ITEM_REQUEST =
    'app/services/AutomaticReport/DELETE_AUTOMATIC_REPORT_ITEM_REQUEST_REQUEST';
export const DELETE_AUTOMATIC_REPORT_ITEM_SUCCESS =
    'app/services/AutomaticReport/DELETE_AUTOMATIC_REPORT_ITEM_SUCCESS_SUCCESS';
export const DELETE_AUTOMATIC_REPORT_ITEM_FAILURE =
    'app/services/AutomaticReport/DELETE_AUTOMATIC_REPORT_ITEM_FAILURE_FAILURE';

export const POST_AUTOMATIC_REPORT_ITEM_REQUEST =
    'app/services/AutomaticReport/POST_AUTOMATIC_REPORT_ITEM_REQUEST_REQUEST';
export const POST_AUTOMATIC_REPORT_ITEM_SUCCESS =
    'app/services/AutomaticReport/POST_AUTOMATIC_REPORT_ITEM_SUCCESS_SUCCESS';
export const POST_AUTOMATIC_REPORT_ITEM_FAILURE =
    'app/services/AutomaticReport/POST_AUTOMATIC_REPORT_ITEM_FAILURE_FAILURE';

export const fetchAutomaticReportListRequest = () => ({
    type: FETCH_AUTOMATIC_REPORT_LIST_REQUEST,
});

export const fetchAutomaticReportListSuccess = (data) => ({
    type: FETCH_AUTOMATIC_REPORT_LIST_SUCCESS,
    payload: data,
});

export const fetchAutomaticReportListFailure = (error) => ({
    type: FETCH_AUTOMATIC_REPORT_LIST_FAILURE,
    error,
});

export const actionDeleteAutomaticReportItemRequest = () => ({
    type: DELETE_AUTOMATIC_REPORT_ITEM_REQUEST,
});

export const actionDeleteAutomaticReportItemSuccess = (data) => ({
    type: DELETE_AUTOMATIC_REPORT_ITEM_SUCCESS,
    payload: data,
});

export const actionDeleteAutomaticReportItemFailure = (error) => ({
    type: DELETE_AUTOMATIC_REPORT_ITEM_FAILURE,
    error,
});

export const actionPostAutomaticReportItemRequest = () => ({
    type: POST_AUTOMATIC_REPORT_ITEM_REQUEST,
});

export const actionPostAutomaticReportItemSuccess = (data) => ({
    type: POST_AUTOMATIC_REPORT_ITEM_SUCCESS,
    payload: data,
});

export const actionPostAutomaticReportItemFailure = (error) => ({
    type: POST_AUTOMATIC_REPORT_ITEM_FAILURE,
    error,
});
