import {
    fetchAutomaticReportListRequest,
    fetchAutomaticReportListSuccess,
    fetchAutomaticReportListFailure,
    actionDeleteAutomaticReportItemRequest,
    actionDeleteAutomaticReportItemSuccess,
    actionDeleteAutomaticReportItemFailure,
    actionPostAutomaticReportItemRequest,
    actionPostAutomaticReportItemSuccess,
    actionPostAutomaticReportItemFailure,
} from './actions';

import {
    retrieveAutomaticReports,
    resourcesDeleteAutomaticReportItem,
    resourcesPostAutomaticReportItem,
} from './resources';

export const fetchAutomaticReportList = (id) => {
    return (dispatch, getState) => {
        const token = getState().getIn(['auth', 'user', 'token']);

        dispatch(fetchAutomaticReportListRequest());
        return retrieveAutomaticReports(token, id)
            .then((response) => {
                dispatch(fetchAutomaticReportListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchAutomaticReportListFailure(error));
            });
    };
};

export const thunksDeleteAutomaticReportItem = (id) => {
    return (dispatch, getState) => {
        const token = getState().getIn(['auth', 'user', 'token']);

        dispatch(actionDeleteAutomaticReportItemRequest());
        return resourcesDeleteAutomaticReportItem(token, id)
            .then((response) => {
                dispatch(actionDeleteAutomaticReportItemSuccess(response.data));
            })
            .catch((error) => {
                dispatch(actionDeleteAutomaticReportItemFailure(error));
            });
    };
};

export const thunksPostAutomaticReportItem = (body) => {
    return (dispatch, getState) => {
        const token = getState().getIn(['auth', 'user', 'token']);

        dispatch(actionPostAutomaticReportItemRequest());
        return resourcesPostAutomaticReportItem(token, body)
            .then((response) => {
                dispatch(actionPostAutomaticReportItemSuccess(response.data));
            })
            .catch((error) => {
                dispatch(actionPostAutomaticReportItemFailure(error));
            });
    };
};
