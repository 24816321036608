// @flow

import { fromJS } from 'immutable';

import {
    DRIVING_REPORT_VALIDATION_ERRORS,
    ECONOMIE_REPORT_VALIDATION_ERRORS,
    START_LOADING_REPORT,
    GET_DRIVING_REPORT_SUCCESS,
    GET_MOVE_REPORT_SUCCESS,
    GET_MOVEV1_REPORT_SUCCESS,
    GET_ECONOMIE_REPORT_SUCCESS,
    GET_DRIVING_REPORT_SUCCESS_CSV,
    GET_DRIVING_REPORT_SUCCESS_PDF,
    GET_DRIVING_REPORT_SUCCESS_XLS,
    REMOVE_REPORT_GENERATION_ERROR,
    GET_DRIVING_REPORT_FAIL,
    GET_MOVE_REPORT_FAIL,
    GET_MOVEV1_REPORT_FAIL,
    MOVE_REPORT_VALIDATION_ERRORS,
    GET_DRIVING_REPORT_BEHAVIOUR_SUCCESS,
    DRIVING_REPORT_BEHAVIOUR_VALIDATION_ERRORS,
    GET_DASHBOARD_REPORT_SUMMARY_SUCCESS,
    START_LOADING_REPORT_DASHBOARD_REPORT_SUMMARY,
    START_LOADING_REPORT_DASHBOARD_REALTIMES_SUMMARY,
    START_LOADING_REPORT_DASHBOARD_GEOFENCE_SUMMARY,
    START_LOADING_REPORT_DASHBOARD_REGENERATION_SUMMARY,
    GET_DASHBOARD_REALTIMES_SUMMARY_SUCCESS,
    GET_DASHBOARD_GEOFENCE_SUMMARY_SUCCESS,
    GET_DASHBOARD_REGENERATION_SUMMARY_SUCCESS,
    GET_DASHBOARD_INSTANT_SUMMARY_SUCCESS,
    START_LOADING_REPORT_DASHBOARD_INSTANT_SUMMARY,
    DASHBOARD_INSTANT_VALIDATION_ERRORS,
    DASHBOARD_GEOFENCE_VALIDATION_ERRORS,
    DASHBOARD_REGENERATION_VALIDATION_ERRORS,
    DASHBOARD_REALTIMES_VALIDATION_ERRORS,
    DASHBOARD_REPORT_VALIDATION_ERRORS,
    GET_GEOFENCING_HISTORY_REPORT_SUCCESS,
    GET_GEOFENCING_HISTORY_REPORT_FAIL,
    CLEAR_MOVE_REPORT,
    CLEAR_MOVEV1_REPORT,
    CLEAR_SPEED_REPORT,
    CLEAR_ECONOMY_REPORT,
    GET_MULTIPLE_HISTORY_REPORT_SUCCESS,
    CLEAR_MULTIPLE_HISTORY_REPORT,
    OPEN_MOVE_REPORT_MODAL,
    CLOSE_MOVE_REPORT_MODAL,
    SET_MOVE_INFO,
    SET_MOVE_TYPE,
    OPEN_DRIVING_REPORT_MODAL,
    CLOSE_DRIVING_REPORT_MODAL,
    SET_DRIVING_INFO,
} from './actions';

import { COMPARE_URL_SIDEMENU } from 'services/SideMenu/actions';
import type { GenericActionType } from 'types';

const initialState = fromJS({
    drivingReportGenerationErrors: {},
    economieReportGenerationErrors: {},
    drivingReportData: {},
    economieReportData: {},
    moveReportGenerationErrors: {},
    drivingBehaviourReportData: {},
    drivingBehaviourReportGenerationErrors: {},
    dashboardInstantSummaryGenerationErrors: {},
    moveReportData: {},
    moveV1ReportData: {},
    loading: false,
    loadingDashboardReportSummary: false,
    loadingDashboardRealtimesSummary: false,
    loadingDashboardGeofenceSummary: false,
    loadingDashboardRegenerationSummary: false,
    loadingDashboardInstantSummary: false,
    economieReportpaginated: {},
    dashboardReportSummary: {},
    dashboardGeofenceSummary: {},
    dashboardRegenerationSummary: {},
    dashboardInstantSummary: {},
    geofencingHistoryData: {},
    moveReportModal: false,
    moveInfo: {},
    moveReportSummary: false,
    drivingReportModal: false,
    drivingInfo: {},
});

function reportServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case DRIVING_REPORT_VALIDATION_ERRORS: {
            return state.set('drivingReportGenerationErrors', fromJS(action.errors));
        }
        case ECONOMIE_REPORT_VALIDATION_ERRORS: {
            return state.set('economieReportGenerationErrors', fromJS(action.errors));
        }
        case MOVE_REPORT_VALIDATION_ERRORS: {
            return state.set('moveReportGenerationErrors', fromJS(action.errors));
        }
        case START_LOADING_REPORT: {
            return state.set('loading', true);
        }
        case START_LOADING_REPORT_DASHBOARD_REPORT_SUMMARY: {
            return state.set('loadingDashboardReportSummary', true);
        }
        case START_LOADING_REPORT_DASHBOARD_REALTIMES_SUMMARY: {
            return state.set('loadingDashboardRealtimesSummary', true);
        }
        case START_LOADING_REPORT_DASHBOARD_GEOFENCE_SUMMARY: {
            return state.set('loadingDashboardGeofenceSummary', true);
        }
        case START_LOADING_REPORT_DASHBOARD_REGENERATION_SUMMARY: {
            return state.set('loadingDashboardRegenerationSummary', true);
        }
        case START_LOADING_REPORT_DASHBOARD_INSTANT_SUMMARY: {
            return state.set('loadingDashboardInstantSummary', true);
        }
        case GET_DRIVING_REPORT_SUCCESS: {
            return state.set('drivingReportData', fromJS(action.payload)).set('loading', false);
        }
        case GET_MOVE_REPORT_SUCCESS: {
            return state.set('moveReportData', fromJS(action.payload)).set('loading', false);
        }
        case GET_MOVEV1_REPORT_SUCCESS: {
            return state.set('moveV1ReportData', fromJS(action.payload)).set('loading', false);
        }
        case GET_GEOFENCING_HISTORY_REPORT_SUCCESS: {
            return state.set('geofencingHistoryData', fromJS(action.payload)).set('loading', false);
        }
        case GET_DRIVING_REPORT_SUCCESS_PDF: {
            return state.set('loading', false);
        }
        case GET_DRIVING_REPORT_SUCCESS_XLS: {
            return state.set('loading', false);
        }
        case GET_DRIVING_REPORT_SUCCESS_CSV: {
            return state.set('loading', false);
        }
        case GET_ECONOMIE_REPORT_SUCCESS: {
            {
                return state
                    .set('economieReportData', fromJS(action.payload))
                    .set('loading', false);
            }
        }
        case GET_DRIVING_REPORT_FAIL: {
            return state.set('drivingReportData', fromJS({})).set('loading', false);
        }
        case GET_MOVE_REPORT_FAIL: {
            return state.set('moveReportData', fromJS({})).set('loading', false);
        }
        case GET_MOVEV1_REPORT_FAIL: {
            return state.set('moveV1ReportData', fromJS({})).set('loading', false);
        }
        case GET_GEOFENCING_HISTORY_REPORT_FAIL: {
            return state.set('geofencingHistoryData', fromJS({})).set('loading', false);
        }
        case REMOVE_REPORT_GENERATION_ERROR: {
            return state.set(action.stateKey, state.get(action.stateKey).delete(action.key));
        }
        case COMPARE_URL_SIDEMENU: {
            return state.set('drivingReportData', fromJS({}));
        }
        case GET_DRIVING_REPORT_BEHAVIOUR_SUCCESS: {
            return state
                .set('drivingBehaviourReportData', fromJS(action.payload))
                .set('loading', false);
        }
        case DRIVING_REPORT_BEHAVIOUR_VALIDATION_ERRORS: {
            return state
                .set('drivingBehaviourReportGenerationErrors', fromJS(action.errors))
                .set('loading', false);
        }
        case GET_DASHBOARD_REPORT_SUMMARY_SUCCESS: {
            return state
                .set('dashboardReportSummary', fromJS(action.payload))
                .set('loadingDashboardReportSummary', false);
        }
        case GET_DASHBOARD_REALTIMES_SUMMARY_SUCCESS: {
            return state
                .set('dashboardRealtimesSummary', fromJS(action.payload))
                .set('loadingDashboardRealtimesSummary', false);
        }
        case GET_DASHBOARD_GEOFENCE_SUMMARY_SUCCESS: {
            return state
                .set('dashboardGeofenceSummary', fromJS(action.payload))
                .set('loadingDashboardGeofenceSummary', false);
        }
        case GET_DASHBOARD_REGENERATION_SUMMARY_SUCCESS: {
            return state
                .set('dashboardRegenerationSummary', fromJS(action.payload))
                .set('loadingDashboardRegenerationSummary', false);
        }
        case GET_DASHBOARD_INSTANT_SUMMARY_SUCCESS: {
            return state
                .set('dashboardInstantSummary', fromJS(action.payload))
                .set('loadingDashboardInstantSummary', false);
        }
        case DASHBOARD_INSTANT_VALIDATION_ERRORS: {
            return state
                .set('dashboardInstantSummaryGenerationErrors', fromJS(action.errors))
                .set('loadingDashboardInstantSummary', false);
        }
        case DASHBOARD_GEOFENCE_VALIDATION_ERRORS: {
            return state
                .set('dashboardInstantSummaryGenerationErrors', fromJS(action.errors))
                .set('loadingDashboardGeofenceSummary', false);
        }
        case DASHBOARD_REGENERATION_VALIDATION_ERRORS: {
            return state
                .set('dashboardInstantSummaryGenerationErrors', fromJS(action.errors))
                .set('loadingDashboardRegenerationSummary', false);
        }
        case DASHBOARD_REALTIMES_VALIDATION_ERRORS: {
            return state
                .set('dashboardInstantSummaryGenerationErrors', fromJS(action.errors))
                .set('loadingDashboardRealtimesSummary', false);
        }
        case DASHBOARD_REPORT_VALIDATION_ERRORS: {
            return state
                .set('dashboardInstantSummaryGenerationErrors', fromJS(action.errors))
                .set('loadingDashboardReportSummary', false);
        }
        case CLEAR_MOVE_REPORT: {
            return state.set('moveReportData', fromJS({})).set('loading', false);
        }
        case CLEAR_MOVEV1_REPORT: {
            return state.set('moveV1ReportData', fromJS({})).set('loading', false);
        }
        case CLEAR_SPEED_REPORT: {
            return state.set('drivingReportData', fromJS({})).set('loading', false);
        }
        case CLEAR_ECONOMY_REPORT: {
            return state.set('economieReportData', fromJS({})).set('loading', false);
        }
        case GET_MULTIPLE_HISTORY_REPORT_SUCCESS: {
            const data = action.payload;
            let multipleHistoryData = state.get('multipleHistoryData');
            if (!multipleHistoryData) {
                multipleHistoryData = new Map();
            }

            const deepCopy = new Map(JSON.parse(JSON.stringify(Array.from(multipleHistoryData))));
            if (data.vehicleId) {
                const history = deepCopy.get(data.vehicleId);
                let link = null;
                let hasAux2Temp = false;
                let hasAux3Temp = false;
                let hasAux4Temp = false;
                let hasRS232Temp1 = false;
                let hasRS232Temp2 = false;
                let hasRS232Temp3 = false;
                let hasRS232Temp4 = false;
                data.links.forEach((ref) => {
                    if (ref.rel === 'next') {
                        link = ref.href;
                    }
                });
                if (history) {
                    history.locations = history.locations.concat(data.location);
                    history.locations.forEach((l) => {
                        if (l.temperatures[0].valueCelsius > -273.15) {
                            history.hasAux2Temp = true;
                        }
                        if (l.temperatures[1].valueCelsius > -273.15) {
                            history.hasAux3Temp = true;
                        }
                        if (l.temperatures[2].valueCelsius > -273.15) {
                            history.hasAux4Temp = true;
                        }
                        if (l.rs232 && l.rs232[0].valueCelsius) {
                            history.hasRS232Temp1 = true;
                        }
                        if (l.rs232 && l.rs232[1].valueCelsius) {
                            history.hasRS232Temp2 = true;
                        }
                        if (l.rs232 && l.rs232[2].valueCelsius) {
                            history.hasRS232Temp3 = true;
                        }
                        if (l.rs232 && l.rs232[3].valueCelsius) {
                            history.hasRS232Temp4 = true;
                        }
                    });
                    history.nextUrl = link;
                    if (!link) {
                        history.finished = true;
                    }

                    deepCopy.set(data.vehicleId, history);
                } else {
                    data.location.forEach((l) => {
                        if (l.temperatures[0].valueCelsius > -273.15) {
                            hasAux2Temp = true;
                        }
                        if (l.temperatures[1].valueCelsius > -273.15) {
                            hasAux3Temp = true;
                        }
                        if (l.temperatures[2].valueCelsius > -273.15) {
                            hasAux4Temp = true;
                        }
                        if (l.rs232 && l.rs232[0].valueCelsius) {
                            hasRS232Temp1 = true;
                        }
                        if (l.rs232 && l.rs232[1].valueCelsius) {
                            hasRS232Temp2 = true;
                        }
                        if (l.rs232 && l.rs232[2].valueCelsius) {
                            hasRS232Temp3 = true;
                        }
                        if (l.rs232 && l.rs232[3].valueCelsius) {
                            hasRS232Temp4 = true;
                        }
                    });
                    deepCopy.set(data.vehicleId, {
                        locations: data.location,
                        nextPage: data.nextPage,
                        nextUrl: link,
                        finished: !link,
                        aux2TempActive: data.aux2TempActive,
                        aux3TempActive: data.aux3TempActive,
                        aux4TempActive: data.aux4TempActive,
                        rs232Active: data.rs232Active,
                        hasAux2Temp,
                        hasAux3Temp,
                        hasAux4Temp,
                        hasRS232Temp1,
                        hasRS232Temp2,
                        hasRS232Temp3,
                        hasRS232Temp4,
                    });
                }
                return state.set('multipleHistoryData', deepCopy).set('loading', false);
            }
            return state.set('loading', false);
        }
        case CLEAR_MULTIPLE_HISTORY_REPORT: {
            return state.set('multipleHistoryData', new Map()).set('loading', false);
        }
        case OPEN_MOVE_REPORT_MODAL: {
            return state.set('moveReportModal', true);
        }
        case CLOSE_MOVE_REPORT_MODAL: {
            return state.set('moveReportModal', false);
        }
        case SET_MOVE_INFO: {
            return state.set('moveInfo', action.payload);
        }
        case SET_MOVE_TYPE: {
            return state.set('moveReportSummary', action.payload);
        }
        case OPEN_DRIVING_REPORT_MODAL: {
            return state.set('drivingReportModal', true);
        }
        case CLOSE_DRIVING_REPORT_MODAL: {
            return state.set('drivingReportModal', false);
        }
        case SET_DRIVING_INFO: {
            return state.set('drivingInfo', action.payload);
        }
        default:
            return state;
    }
}

export default reportServiceReducer;
