/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype-errors/show-errors */
// @flow

import moment from 'moment';

import {
    receivedDrivingReportGenerateValidationError,
    receivedEconomieReportGenerateValidationError,
    receivedGenerateDrivingReportSuccess,
    receivedGenerateDrivingReportSuccessPDF,
    receivedGenerateDrivingReportSuccessXLS,
    receivedGenerateDrivingReportSuccessCSV,
    receivedGenerateEconomieReportSuccess,
    receivedRemoteDrivingReportError,
    receivedRemoteEconomieReportError,
    receivedGenerateDrivingReportError,
    receivedGenerateMoveReportError,
    startLoadingReport,
    startLoadingDashboardReportSummary,
    startLoadingDashboardRealtimesSummary,
    startLoadingDashboardGeofenceSummary,
    startLoadingDashboardRegenerationSummary,
    startLoadingDashboardInstantSummary,
    receivedGenerateMoveReportSuccess,
    receivedGenerateMoveV1ReportSuccess,
    receivedRemoteMoveReportError,
    receivedMoveReportGenerateValidationError,
    receivedGenerateDrivingBehaviourReportSuccess,
    receivedGenerateDrivingBehaviourReportError,
    receivedGenerateDashboardInstantError,
    receivedGenerateDashboardGeofenceError,
    receivedGenerateDashboardRegenerationError,
    receivedGenerateDashboardRealtimesError,
    receivedGenerateDashboardReportError,
    receivedGenerateDashboardReportSummarySuccess,
    receivedGenerateDashboardRealtimesSummarySuccess,
    receivedGenerateDashboardGeofenceSummarySuccess,
    receivedGenerateDashboardRegenerationSummarySuccess,
    receivedGenerateDashboardInstantSummarySuccess,
    receivedGenerateGeofencingHistoryReportSuccess,
    receivedGenerateGeofencingHistoryReportError,
    clearMoveReport,
    clearMoveV1Report,
    receivedGenerateMultipleHistoryReportSuccess,
    clearMultipleHistory,
    clearSpeedReport,
    clearEcoReport,
    receivedOpenMoveReportModal,
    receivedCloseMoveReportModal,
    receivedMoveInfo,
    receivedMoveReportType,
    receivedOpenDrivingReportModal,
    receivedCloseDrivingReportModal,
    receivedDrivingInfo,
} from './actions';
import {
    requestGenerateDrivingReport,
    requestGenerateMoveReport,
    requestGenerateEconomieReport,
    requestGenerateDrivingBehaviourReport,
    requestGenerateDashboardReportSummary,
    requestGenerateDashboardRealtimesSummary,
    requestGenerateDashboardGeofenceSummary,
    requestGenerateDashboardRegenerationSummary,
    requestGenerateDashboardInstantSummary,
    requestGenerateGeofencingHistoryReport,
    requestGenerateMoveV1Report,
    getEquipmentLocations,
    retrieveHistoryModification,
    retrieveHistoryModificationByBranchId,
    requestGenerateMoveV1ProPerso,
} from './resources';

import { appLocales } from 'i18n';

import { PRESET_PERIODS, OUTPUT_FORMATS } from './constants';

import { DrivingReportGeneratorType, MoveReportGeneratorType } from 'types';

import { isImperial } from '../Units/thunks';
import { timezoneConverter } from '../timezoneConverter';
import { handleConvertUTC } from '../timezoneConverter';
import type { ImmutableMap } from 'types';
import { receivedMaintenanceError } from '../Maintenance/action';

const MAX_INTEGER = 2147483647;

export const clearDrivingReport = () => (dispatch: ReduxDispatch) => {
    dispatch(clearSpeedReport());
};
export const resetMoveReport = () => (dispatch: ReduxDispatch) => {
    dispatch(clearMoveReport());
};
export const resetMoveV1Report = () => (dispatch: ReduxDispatch) => {
    dispatch(clearMoveV1Report());
};
export const clearEconomyReport = () => (dispatch: ReduxDispatch) => {
    dispatch(clearEcoReport());
};
export const validateDrivingReportGenerator = (data: DrivingReportGeneratorType) => (
    dispatch: ReduxDispatch
) => {
    const requiredFields = ['format', 'periodFrom', 'periodTo', 'type', 'equipmentIds'];
    const errors = {};
    requiredFields.forEach((field) => {
        if (!data[field] || (Array.isArray(data[field]) && !data[field].length)) {
            errors[field] = [''];
        }
    });

    return new Promise((resolve, reject) => {
        if (Object.keys(errors).length > 0) {
            dispatch(receivedDrivingReportGenerateValidationError(errors));
            return reject();
        } else {
            return resolve();
        }
    });
};

export const validateEconomieReportGenerator = (data: *) => (dispatch: ReduxDispatch) => {
    const requiredFields = ['format', 'periodFrom', 'periodTo', 'type', 'equipmentIds'];
    const errors = {};
    requiredFields.forEach((field) => {
        if (!data[field] || (Array.isArray(data[field]) && !data[field].length)) {
            errors[field] = [''];
        }
    });

    return new Promise((resolve, reject) => {
        if (Object.keys(errors).length > 0) {
            dispatch(receivedEconomieReportGenerateValidationError(errors));
            return reject();
        } else {
            return resolve();
        }
    });
};

export const validateMoveReportGenerator = (data: MoveReportGeneratorType) => (
    dispatch: ReduxDispatch
) => {
    const requiredFields = ['format', 'periodFrom', 'periodTo', 'type', 'equipmentIds'];
    const errors = {};
    requiredFields.forEach((field) => {
        if (!data[field] || (Array.isArray(data[field]) && !data[field].length)) {
            errors[field] = [''];
        }
    });

    if (
        !(
            (data.periodTimeFrom.length && data.periodTimeTo.length) ||
            (!data.periodTimeFrom.length && !data.periodTimeTo.length)
        )
    ) {
        errors.periodTimeFrom = [''];
        errors.periodTimeTo = [''];
    }

    return new Promise((resolve, reject) => {
        if (Object.keys(errors).length > 0) {
            dispatch(receivedMoveReportGenerateValidationError(errors));
            return reject();
        } else {
            return resolve();
        }
    });
};
export const generateDrivingReport = (data: DrivingReportGeneratorType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);
    const isImperial = getState().getIn(['units', 'unit']) === 'imperial';
    // groom data to match request.
    const requestData = {
        format: 'display',
        type: data.type,
        // period: data.period.toLowerCase(),
        equipmentIds: data.equipmentIds.filter((id) => id < MAX_INTEGER),
        is_imperial: isImperial,
    };

    requestData.startDate = data.periodFrom;
    requestData.endDate = data.periodTo;

    let action;
    if (data.format === OUTPUT_FORMATS.csv) {
        // csv action
        action = receivedGenerateDrivingReportSuccessCSV;
    } else if (data.format === OUTPUT_FORMATS.pdf) {
        // pdf action
        action = receivedGenerateDrivingReportSuccess;
    } else if (data.format === OUTPUT_FORMATS.xls) {
        // xls action
        action = receivedGenerateDrivingReportSuccess;
    } else {
        action = receivedGenerateDrivingReportSuccess;
    }

    return requestGenerateDrivingReport(token, requestData)
        .then((response) => {
            response.data.data.overSpeedLimit.data.map((vehicle) => {
                const endDate =
                    moment(vehicle.startTime).format('YYYY-MM-DD') + ' ' + vehicle.endTime;
                vehicle.startTime = handleConvertUTC(vehicle.startTime);
                vehicle.endTime = handleConvertUTC(vehicle.endTime);
                return vehicle;
            });
            return dispatch(action(response.data));
        })
        .catch((error) => dispatch(receivedGenerateDrivingReportError(error)));
};

export const generateMoveReport = (data: MoveReportGeneratorType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);
    const isImperial = getState().getIn(['units', 'unit']) === 'imperial';

    // groom data to match request.
    const requestData = {
        format: data.format,
        type: data.type,
        period: data.period.toLowerCase(),
        equipmentIds: data.equipmentIds.filter((id) => id < MAX_INTEGER),
        street: data.street,
        place_name: data.placeName,
        position: data.position,
        periodTimeFrom: data.periodTimeFrom,
        periodTimeTo: data.periodTimeTo,
        report_filter: data.reportFilter,
        is_imperial: isImperial,
    };

    if (data.period === PRESET_PERIODS.custom) {
        requestData.startDate = data.periodFrom;
        requestData.endDate = data.periodTo;
    }

    let action;
    if (data.format === OUTPUT_FORMATS.csv) {
        // csv action
        action = receivedGenerateDrivingReportSuccessCSV;
    } else if (data.format === OUTPUT_FORMATS.pdf) {
        // pdf action
        action = receivedGenerateDrivingReportSuccessPDF;
    } else if (data.format === OUTPUT_FORMATS.xls) {
        // xls action
        action = receivedGenerateDrivingReportSuccessXLS;
    } else {
        action = receivedGenerateMoveReportSuccess;
    }

    return requestGenerateMoveReport(locale, token, requestData)
        .then((response) => {
            response.data.data.moveSummary.displaydata.map((move) => {
                move.startTime = timezoneConverter(move.startTime);
                move.endTime = timezoneConverter(move.endTime);
                return move;
            });

            if (
                action &&
                (data.format === OUTPUT_FORMATS.csv ||
                    data.format === OUTPUT_FORMATS.pdf ||
                    data.format === OUTPUT_FORMATS.xls)
            ) {
                console.log(appLocales);
                // Link with blob data to be downloaded
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.fileName}.${data.format}`);
                link.setAttribute('id', 'download-file');
                document.body.appendChild(link);

                // Give it a little time to load, click to download it, and then remove it
                return setTimeout(() => {
                    link.click();
                    link.parentNode.removeChild(link);
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(url);
                    return dispatch(action());
                }, 1000);
            } else if (action) {
                return dispatch(action(response.data));
            }

            return Promise.reject('No action. Check the output format.');
        })
        .catch((error) => dispatch(receivedGenerateMoveReportError(error)));
};

export const generateMoveV1Report = (data: MoveReportGeneratorType, distance) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);
    const isImperial = getState().getIn(['units', 'unit']) === 'imperial';

    const startTime =
        data.periodTimeFrom === '' ? data.periodTimeFrom : `${data.periodTimeFrom}:00`;
    const endTime = data.periodTimeTo === '' ? data.periodTimeTo : `${data.periodTimeTo}:00`;

    const dateStart = `${data.periodFrom} ${startTime}`;
    const dateEnd = `${data.periodTo} ${endTime}`;
    const actualTimeZone = moment.tz.guess();

    const localTimeZoneStart = moment.utc(dateStart).tz(actualTimeZone, true);
    const convertedDateStart = moment.utc(localTimeZoneStart).tz('America/Montreal');

    const localTimeZoneEnd = moment.utc(dateEnd).tz(actualTimeZone, true);
    const convertedDateEnd = moment.utc(localTimeZoneEnd).tz('America/Montreal');
    // groom data to match request.
    const requestData = {
        format: data.format,
        type: data.type,
        period: data.period.toLowerCase(),
        equipmentIds: data.equipmentIds.filter((id) => id < MAX_INTEGER),
        street: data.street,
        place_name: data.placeName,
        position: data.position,
        periodTimeFrom: startTime === '' ? data.periodTimeFrom : convertedDateStart.format('HH:mm'),
        periodTimeTo: endTime === '' ? data.periodTimeTo : convertedDateEnd.format('HH:mm'),
        report_filter: data.reportFilter,
        is_imperial: isImperial,
        distance_range: distance,
    };

    if (data.period === PRESET_PERIODS.custom) {
        requestData.startDate =
            startTime === '' ? data.periodFrom : convertedDateStart.format('YYYY-MM-DD');
        requestData.endDate =
            endTime === '' ? data.periodTo : convertedDateEnd.format('YYYY-MM-DD');
    }

    let action;
    if (data.format === OUTPUT_FORMATS.csv) {
        // csv action
        action = receivedGenerateDrivingReportSuccessCSV;
    } else if (data.format === OUTPUT_FORMATS.pdf) {
        // pdf action
        action = receivedGenerateDrivingReportSuccessPDF;
    } else if (data.format === OUTPUT_FORMATS.xls) {
        // xls action
        action = receivedGenerateDrivingReportSuccessXLS;
    } else {
        action = receivedGenerateMoveV1ReportSuccess;
    }

    return requestGenerateMoveV1Report(locale, token, requestData)
        .then((response) => {
            if (data.period === PRESET_PERIODS.custom) {
                response.data.meta.filters.periodetimeStart = data.periodTimeFrom;
                response.data.meta.filters.periodetimeEnd = data.periodTimeTo;
            }
            for (const vehicle in response.data.data.moveSummary.data) {
                response.data.data.moveSummary.data[vehicle].data.map((move) => {
                    move.startTime = timezoneConverter(move.startTime);
                    move.endTime = timezoneConverter(move.endTime);
                    move.totalDistance = `${parseFloat(move.totalDistance.split(' ')[0]).toFixed(
                        2
                    )} ${move.totalDistance.split(' ')[1]}`;
                    return move;
                });
            }
            if (
                action &&
                (data.format === OUTPUT_FORMATS.csv ||
                    data.format === OUTPUT_FORMATS.pdf ||
                    data.format === OUTPUT_FORMATS.xls)
            ) {
                // Link with blob data to be downloaded
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.fileName}.${data.format}`);
                link.setAttribute('id', 'download-file');
                document.body.appendChild(link);

                // Give it a little time to load, click to download it, and then remove it
                return setTimeout(() => {
                    link.click();
                    link.parentNode.removeChild(link);
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(url);
                    return dispatch(action());
                }, 1000);
            } else if (action) {
                return dispatch(action(response.data));
            }
            return Promise.reject('No action. Check the output format.');
        })
        .catch((error) => dispatch(receivedGenerateMoveV1ReportError(error)));
};

export const generateIsRouteProPerso = (reportId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return requestGenerateMoveV1ProPerso(token, reportId) // 108910329
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                // dispatch(receivedGenerateDrivingBehaviourReportError(error));
            }
            // return dispatch(receivedGenerateDrivingBehaviourReportSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

export const generateEconomieReport = (data: DrivingReportGeneratorType, page: number = 1) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);
    const isImperial = getState().getIn(['units', 'unit']) === 'imperial';

    // groom data to match request.
    const requestData = {
        format: data.format,
        type: data.type,
        // period: data.period.toLowerCase(),
        equipmentIds: data.equipmentIds.filter((id) => id < MAX_INTEGER),
        gasprice: data.gasPrice,
        dieselprice: data.dieselPrice,
        is_imperial: isImperial,
    };

    requestData.startDate = data.periodFrom;
    requestData.endDate = data.periodTo;

    let action;
    if (data.format === OUTPUT_FORMATS.csv) {
        // csv action
        action = receivedGenerateDrivingReportSuccessCSV;
    } else if (data.format === OUTPUT_FORMATS.pdf) {
        // pdf action
        action = receivedGenerateDrivingReportSuccessPDF;
    } else if (data.format === OUTPUT_FORMATS.xls) {
        // xls action
        action = receivedGenerateDrivingReportSuccessXLS;
    } else {
        action = receivedGenerateEconomieReportSuccess;
    }

    return requestGenerateEconomieReport(locale, token, requestData)
        .then((response) => {
            if (
                action &&
                (data.format === OUTPUT_FORMATS.csv ||
                    data.format === OUTPUT_FORMATS.pdf ||
                    data.format === OUTPUT_FORMATS.xls)
            ) {
                // Link with blob data to be downloaded
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.fileName}.${data.format}`);
                link.setAttribute('id', 'download-file');
                document.body.appendChild(link);

                // Give it a little time to load, click to download it, and then remove it
                return setTimeout(() => {
                    link.click();
                    link.parentNode.removeChild(link);
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(url);
                    return dispatch(action());
                }, 1000);
            } else if (action) {
                return dispatch(action(response.data));
            }

            return Promise.reject('No action. Check the output format.');
        })
        .catch((error) => dispatch(receivedGenerateDrivingReportError(error)));
};

export const removeDrivingReportError = (key: string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedRemoteDrivingReportError(key));

export const removeMoveReportError = (key: string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedRemoteMoveReportError(key));

export const removeEconomieReportError = (key: string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedRemoteEconomieReportError(key));

export const generateDrivingBehaviourReport = (userId: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingReport());
    return requestGenerateDrivingBehaviourReport(userId, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDrivingBehaviourReportError(error));
            }
            return dispatch(receivedGenerateDrivingBehaviourReportSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDrivingBehaviourReportError(error));
        });
};

export const generateDashboardReportSummary = (startDate: string, endDate: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingDashboardReportSummary());
    return requestGenerateDashboardReportSummary(startDate, endDate, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDashboardReportError(error));
            }
            return dispatch(receivedGenerateDashboardReportSummarySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDashboardReportError(error));
        });
};

export const generateDashboardRealtimesSummary = (startDate: string, endDate: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingDashboardRealtimesSummary());
    return requestGenerateDashboardRealtimesSummary(startDate, endDate, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDashboardRealtimesError(error));
            }
            return dispatch(receivedGenerateDashboardRealtimesSummarySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDashboardRealtimesError(error));
        });
};

export const generateDashboardGeofenceSummary = (startDate: string, endDate: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingDashboardGeofenceSummary());
    return requestGenerateDashboardGeofenceSummary(startDate, endDate, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDashboardGeofenceError(error));
            }
            return dispatch(receivedGenerateDashboardGeofenceSummarySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDashboardGeofenceError(error));
        });
};
export const generateDashboardRegenerationSummary = (startDate: string, endDate: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingDashboardRegenerationSummary());
    return requestGenerateDashboardRegenerationSummary(startDate, endDate, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDashboardRegenerationError(error));
            }
            return dispatch(receivedGenerateDashboardRegenerationSummarySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDashboardRegenerationError(error));
        });
};

export const generateDashboardInstantSummary = () => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    dispatch(startLoadingDashboardInstantSummary());

    return requestGenerateDashboardInstantSummary(token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGenerateDashboardInstantError(error));
            }
            return dispatch(receivedGenerateDashboardInstantSummarySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGenerateDashboardInstantError(error));
        });
};

export const generateGeofencingHistoryReport = (data: MoveReportGeneratorType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);

    // groom data to match request.
    const startTime = data.periodTimeFrom === '' ? '00:00' : data.periodTimeFrom;
    const endTime = data.periodTimeTo === '' ? '24:00' : data.periodTimeTo;
    const requestData = {
        vehiclesId: data.equipmentIds.filter((id) => id < MAX_INTEGER),
        dateStart: `${data.periodFrom} ${startTime}`,
        dateEnd: `${data.periodTo} ${endTime}`,
        format: data.format,
        type: data.outputType || 0,
    };

    let action;
    if (data.format === OUTPUT_FORMATS.csv) {
        // csv action
        action = receivedGenerateDrivingReportSuccessCSV;
    } else if (data.format === OUTPUT_FORMATS.pdf) {
        // pdf action
        action = receivedGenerateDrivingReportSuccessPDF;
    } else if (data.format === OUTPUT_FORMATS.xls) {
        // xls action
        action = receivedGenerateDrivingReportSuccessXLS;
    } else {
        action = receivedGenerateGeofencingHistoryReportSuccess;
    }

    return requestGenerateGeofencingHistoryReport(token, requestData)
        .then((response) => {
            console.log(response.data);
            if (
                action &&
                (data.format === OUTPUT_FORMATS.csv ||
                    data.format === OUTPUT_FORMATS.pdf ||
                    data.format === OUTPUT_FORMATS.xls)
            ) {
                // Link with blob data to be downloaded
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `geofencingReport.${data.format}`);
                link.setAttribute('id', 'download-file');
                document.body.appendChild(link);

                // Give it a little time to load, click to download it, and then remove it
                return setTimeout(() => {
                    link.click();
                    link.parentNode.removeChild(link);
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(url);
                    return dispatch(action());
                }, 1000);
            } else if (action) {
                return dispatch(action(response.data));
            }

            return Promise.reject('No action. Check the output format.');
        })
        .catch((error) => dispatch(receivedGenerateGeofencingHistoryReportError(error)));
};

export const getLocations = (link, vehicleId, data, pageNumber) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingReport());
    const token = `${getState().getIn(['auth', 'user', 'token'])}`;
    const locale = getState().getIn(['language', 'locale']);
    let startTime = null;
    let endTime = null;
    let dateStart = null;
    let dateEnd = null;
    if (!link) {
        startTime = data.periodTimeFrom === '' ? '00:00:00' : `${data.periodTimeFrom}:00`;
        endTime = data.periodTimeTo === '' ? '23:59:59' : `${data.periodTimeTo}:00`;
        dateStart = moment(`${data.periodFrom}T${startTime}Z`, 'YYYY-MM-DDTHH:mm')
            .utc()
            .format();
        dateEnd = moment(`${data.periodTo}T${endTime}Z`, 'YYYY-MM-DDTHH:mm')
            .utc()
            .format();
    }
    return getEquipmentLocations(token, link, vehicleId, dateStart, dateEnd, pageNumber, 1000)
        .then((response) => {
            if (Object.keys(response.data).length > 3) {
                response.data.location.map((location) => {
                    return location;
                });
            }
            return dispatch(receivedGenerateMultipleHistoryReportSuccess(response.data));
        })
        .catch((error) => dispatch(receivedGenerateGeofencingHistoryReportError(error)));
};

export const clearMultipleHistoryReport = () => (dispatch: ReduxDispatch) => {
    dispatch(clearMultipleHistory());
};

export const getHistoryModification = (vehicleId: number, data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveHistoryModification(token, vehicleId, data)
        .then((response: Object) => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch((error) => {});
};

export const getHistoryModificationByBranchId = (branchId: number, data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveHistoryModificationByBranchId(token, branchId, data)
        .then((response: Object) => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch((error) => {});
};

export const openMoveReportModal = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedOpenMoveReportModal());
};
export const closeMoveReportModal = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedCloseMoveReportModal());
};
export const openDrivingReportModal = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedOpenDrivingReportModal());
};
export const closeDrivingReportModal = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedCloseDrivingReportModal());
};
export const setMoveInfo = (
    vehicleId,
    vehiculeNumb,
    distance,
    periodDateFrom,
    periodDateTo,
    periodTimeFrom,
    periodTimeTo,
    driverName,
    driverChipColor,
    stopAddress,
    startAddress,
    isPrivateMode,
    reportId,
    toggleRef
) => (dispatch: ReduxDispatch) => {
    const data = {
        vehicleId,
        vehiculeNumb,
        distance,
        periodDateFrom,
        periodDateTo,
        periodTimeFrom,
        periodTimeTo,
        driverName,
        driverChipColor,
        stopAddress,
        startAddress,
        isPrivateMode,
        reportId,
        toggleRef,
    };
    dispatch(receivedMoveInfo(data));
};
export const setDrivingInfo = (
    vehiculeNumb,
    distance,
    date,
    time,
    speed,
    speedLimit,
    maximumSpeed,
    driverName,
    entryLat,
    entryLong,
    exitLat,
    exitLong,
    geoName,
    driverChipColor
) => (dispatch: ReduxDispatch) => {
    const data = {
        vehiculeNumb,
        distance,
        date,
        time,
        speed,
        speedLimit,
        maximumSpeed,
        driverName,
        entryLat,
        entryLong,
        exitLat,
        exitLong,
        geoName,
        driverChipColor,
    };
    dispatch(receivedDrivingInfo(data));
};
export const setMoveType = (type) => (dispatch: ReduxDispatch) => {
    dispatch(receivedMoveReportType(type));
};
