exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);", ""]);

// module
exports.push([module.id, ".ap-container {\n  background-color: #F5F7F9;\n  margin: 1% !important;\n}\n\n.css-1e6y48t-MuiButtonBase-root-MuiButton-root {\n  min-width: 34px !important;\n}", ""]);

// exports
