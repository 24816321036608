// @flow

import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Container, NavigationWrapper } from 'styles/common';

import ProtectedRoute from 'containers/ProtectedRoute';
import SideMenuContainer from 'containers/SideMenuContainer';

import SideMenuComponent from 'components/SideMenu';
import SideMenuOrganizationsComponent from 'components/SideMenuOrganization';
import ActionSideMenuComponent from 'components/ActionSideMenu';

import ReportSideMenuComponent from 'components/ReportsSideMenu';
import AdminSideMenuComponent from 'components/AdminSideMenu';

import GroupsPage from 'views/Groups';
import HomePage from 'views/HomePage';
import LoginPage from 'views/LoginPage';
import NotFound from 'views/NotFound';
import OrganizationListPage from 'views/OrganizationListPage';
import OrganizationPage from 'views/OrganizationPage';
import UsersListPage from 'views/UsersListPage';
import BranchPage from 'views/BranchPage';
import DivisionPage from 'views/DivisionPage';
import UserListPage from 'views/UserListPage';
import EquipmentListPage from 'views/EquipmentListPage';
// import MapPage from 'views/MapPage';
import ForgotPasswordPage from 'views/ForgotPasswordPage';
import CreatePasswordPage from 'views/CreatePasswordPage';
import ReportPage from 'views/ReportPage';
import AdminPage from 'views/AdminPage';
import MaintenancePage from 'views/MaintenancePage';
import Forbidden from 'views/403';
import Root from 'containers/RootHandler';
// Reports
// Driving report
import DrivingReportGenerator from 'containers/ReportGenerator/Driving';
// Driving report
import DrivingBehaviorSisense from 'containers/ReportGenerator/DrivingBehaviorSisense';
// Move report
import MoveReportGenerator from 'containers/ReportGenerator/Move';
// Move report v1
import MoveReportGeneratorV1 from 'containers/ReportGenerator/MoveV1';

import MultipleHistoryReport from 'containers/ReportGenerator/MultipleHistory';
// Temperature report
import TemperatureReport from 'containers/ReportGenerator/Temperature';

import GeofencingHistoryReportGenerator from 'containers/ReportGenerator/GeofencingHistory';
// Economie report
import EconomieReportGenerator from 'containers/ReportGenerator/Economie';
// Automatic report
import AutomaticReports from 'containers/ReportGenerator/AutomaticReports';
// Dashbord
import DashboardGenerator from 'containers/ReportGenerator/Dashboard';

import AlarmContainer from 'containers/Maintenance';

import Users from 'containers/AdminContainers/Users';
import Branch from 'containers/AdminContainers/Branch';
import Driver from 'containers/AdminContainers/Driver';
import Category from 'containers/AdminContainers/Category';
// auto-partage
import ApHomeReservation from './containers/ApHomeReservation';

import Callback from 'views/Callback';
import BranchListPage from 'views/BranchListPage';
import ReportComportement from 'containers/ReportComportement';
import ApSidebar from './components/ApSideMenu';

// import MapPage from 'views/MapPage';
// import FleetOverviewComponent from 'components/FleetOverview';

const MapPage = lazy(() => import('views/MapPage'));
const FleetOverviewComponent = lazy(() => import('components/FleetOverview'));

const locale = ':locale(en|fr|es)';
const equipmentRoutes = [
    `/${locale}/interne/orgs/:organizationId/equipment`,
    `/${locale}/interne/orgs/:organizationId/branch/:branchId/equipment`,
    `/${locale}/interne/orgs/:organizationId/branch/:branchId/division/:divisionId/equipment`,
];

const userRoutes = [
    `/${locale}/interne/orgs/:organizationId/users`,
    `/${locale}/interne/orgs/:organizationId/branch/:branchId/users`,
    `/${locale}/interne/orgs/:organizationId/branch/:branchId/division/:divisionId/users`,
];
const renderLoader = () => <p />;
const renderEmptyLoader = () => <p />;
function Routes() {
    const storedLocale = localStorage.getItem('locale') || 'fr';
    return (
        <Switch>
            {/* NOT PROTECTED */}
            <Route path="/" exact component={Root} />
            <Route path="/login" exact>
                <Redirect to={`/${storedLocale}/login`} />
            </Route>
            <Route path="/callback" component={Callback} />
            <Route
                exact
                path={`/${locale}`}
                // eslint-disable-next-line react/jsx-no-bind
                render={({ match }) => <Redirect to={`/${match.params.locale}/login`} />}
            />
            ,
            <Route path={`/${locale}/login`} exact>
                <Container.NoHeader>
                    <LoginPage />
                </Container.NoHeader>
            </Route>
            <Route path="/interne" exact>
                <Redirect to={`/${storedLocale}/admin/login`} />
            </Route>
            <Route
                exact
                path={`/${locale}/interne`}
                // eslint-disable-next-line react/jsx-no-bind
                render={({ match }) => <Redirect to={`/${match.params.locale}/admin/login`} />}
            />
            ,
            <Route path={`/${locale}/admin/login`} exact>
                <Container.NoHeader>
                    <LoginPage adminLogin />
                </Container.NoHeader>
            </Route>
            {/* end - NOT PROTECTED */}
            {/* PROTECTED */}
            {/* TO REVIEW */}
            <ProtectedRoute path={`/${locale}/admin/groups`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <GroupsPage />
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/interne`} admin exact>
                <NavigationWrapper>
                    <SideMenuContainer>
                        <SideMenuComponent />
                    </SideMenuContainer>
                    <Container>
                        <HomePage />
                    </Container>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/map`} exact>
                <NavigationWrapper map>
                    <SideMenuContainer offset={60} mapPage>
                        <ActionSideMenuComponent>
                            <Suspense fallback={renderEmptyLoader()}>
                                <FleetOverviewComponent />
                            </Suspense>
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Map>
                        <Suspense fallback={renderLoader()}>
                            <MapPage />
                        </Suspense>
                    </Container.Map>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Menu'} />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/dashboards/:dashboardId`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Dashboard'}>
                            <DrivingBehaviorSisense />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/speeding`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Dépassement de Vitesse'}>
                            <DrivingReportGenerator />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/travels-dynamic`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Déplacement Dynamique'}>
                            <MoveReportGenerator />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/travels`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Déplacement'}>
                            <MoveReportGeneratorV1 />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/temp-monitoring`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Suivi Températures'}>
                            <MultipleHistoryReport />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            {/* NEW */}
            <ProtectedRoute path={`/${locale}/reports/automatic-reports`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Automatic Reports'}>
                            <AutomaticReports />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            {/* NEW */}
            <ProtectedRoute path={`/${locale}/reports/temp-graph`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Graphique Températures'}>
                            <TemperatureReport />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/driving-behaviours`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Comportement Conduites'}>
                            <ReportComportement />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/geofences`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Geofences'}>
                            <GeofencingHistoryReportGenerator />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/fuelsaving`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={"Rapport - Économie d'essence"}>
                            <EconomieReportGenerator />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/reports/dashboards`} exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <ReportSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <ReportPage title={'Rapport - Dashboard'}>
                            <DashboardGenerator />
                        </ReportPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/maintenance/alarms`} exact>
                <NavigationWrapper>
                    <ActionSideMenuComponent />
                    <Container.Dashboard>
                        <MaintenancePage>
                            <AlarmContainer />
                        </MaintenancePage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/autopartage`} exact>
                <NavigationWrapper>
                    <ActionSideMenuComponent />
                    {/* <ApHomeReservation />*/}
                    <ApSidebar />
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/admin`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <AdminPage title={'Admin - Menu'} />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/admin/users`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <AdminPage title={'Admin - Utilisateurs'}>
                            <Users />
                        </AdminPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/admin/organization`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <AdminPage title={'Admin - Organisation'}>
                            <Branch />
                        </AdminPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/admin/drivers`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <AdminPage title={'Admin - Conducteurs'}>
                            <Driver />
                        </AdminPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/admin/categories`} clientAdmin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent>
                            <AdminSideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <AdminPage title={'Admin - Catégories'}>
                            <Category />
                        </AdminPage>
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/interne/orgs/`} admin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <OrganizationListPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/interne/users/`} admin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuComponent />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <UsersListPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/interne/orgs/:organizationId/branches`} admin exact>
                <NavigationWrapper>
                    <SideMenuContainer offset={60}>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuOrganizationsComponent organizationDetail />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <BranchListPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute path={`/${locale}/interne/orgs/:organizationId`} admin exact>
                <NavigationWrapper>
                    <SideMenuContainer>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuOrganizationsComponent organizationDetail />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <OrganizationPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute
                path={`/${locale}/interne/orgs/:organizationId/branch/:branchId`}
                admin
                exact
            >
                <NavigationWrapper>
                    <SideMenuContainer>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuOrganizationsComponent branchDetail />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <BranchPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <ProtectedRoute
                path={`/${locale}/interne/orgs/:organizationId/branch/:branchId/division/:divisionId`}
                admin
                exact
            >
                <NavigationWrapper>
                    <SideMenuContainer>
                        <ActionSideMenuComponent isAdmin={true}>
                            <SideMenuOrganizationsComponent divisionDetail />
                        </ActionSideMenuComponent>
                    </SideMenuContainer>
                    <Container.Dashboard>
                        <DivisionPage />
                    </Container.Dashboard>
                </NavigationWrapper>
            </ProtectedRoute>
            <Route path={`/${locale}/forgot-password`} exact>
                <Container.NoHeader>
                    <ForgotPasswordPage />
                </Container.NoHeader>
            </Route>
            <Route path={`/${locale}/forgot-password/:token`} exact>
                <Container.NoHeader>
                    <ForgotPasswordPage resetPasswordPage />
                </Container.NoHeader>
            </Route>
            <Route path={`/${locale}/create-password/:token`} exact>
                <Container.NoHeader>
                    <CreatePasswordPage />
                </Container.NoHeader>
            </Route>
            {equipmentRoutes.map((route) => (
                <ProtectedRoute key={route} path={route} admin exact>
                    <NavigationWrapper>
                        <SideMenuContainer>
                            <ActionSideMenuComponent isAdmin={true}>
                                <SideMenuOrganizationsComponent />
                            </ActionSideMenuComponent>
                        </SideMenuContainer>
                        <Container.Dashboard>
                            <EquipmentListPage />
                        </Container.Dashboard>
                    </NavigationWrapper>
                </ProtectedRoute>
            ))}
            {userRoutes.map((route) => (
                <ProtectedRoute key={route} path={route} admin exact>
                    <NavigationWrapper>
                        <SideMenuContainer>
                            <ActionSideMenuComponent isAdmin={true}>
                                <SideMenuOrganizationsComponent />
                            </ActionSideMenuComponent>
                        </SideMenuContainer>
                        <Container.Dashboard>
                            <UserListPage />
                        </Container.Dashboard>
                    </NavigationWrapper>
                </ProtectedRoute>
            ))}
            {/* end - PROTECTED */}
            <Route path={'/:locale/403'} component={Forbidden} />
            <Route component={NotFound} />
        </Switch>
    );
}

export default Routes;
