// @flow

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import CloseButton from 'components/CloseButton';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { parseJWT } from 'helpers';
import { selectUser } from 'services/Authentication/selectors';
import {
    selectIsSideMenuOpen,
    selectIsActiveBarOpen,
    selectIsFleetOverviewOpen,
    selectIsLangUnitConfigured,
} from 'services/SideMenu/selectors';
import { selectShowModal, selectModalType, selectModalError } from 'services/Modal/selectors';
import { selectLoggedInAsUser } from 'services/Authentication/selectors';

import colors from 'styles/colors';
import { changeLocale } from 'services/Language/thunks';
import { changeUnit } from 'services/Units/thunks';
import {
    toggleSideMenu,
    toggleActiveBar,
    hideSideMenu,
    showSideMenu,
    hideActiveBar,
    showActiveBar,
    hideFleetOverview,
    showFleetOverview,
} from 'services/SideMenu/thunks';
import { changeLangUnitConfig, getLangUnitConfig } from 'services/Admin/thunks';
import { closeModal, toggleChangePasswordModal, changeUserPassword } from 'services/Modal/thunks';

import { selectLocale } from 'services/Language/selectors';
import { logoutUser } from 'services/Authentication/thunks';
import { selectUnit } from 'services/Units/selectors';
import { FormattedMessage, injectIntl } from 'react-intl';

import SideMenuComponent from 'components/SideMenu';
import SideMenuItem from 'components/SideMenuItem';

import { SideMenu, Header, Footer, Image } from 'styles/sidemenu';
import ActionSideMenu from './styles.js';
import SelectField from 'components/SelectField';
import {
    Menu,
    LogoWhiteGreen,
    LogoAndLogoType,
    Map,
    UserNetwork,
    Truck,
    CheckMark,
    Search,
    Notification,
    User,
    Users,
    Gear,
    ArrowLeaving,
    World,
    ArrowLeft,
    Building,
    Document,
    Admin,
    Settings,
    Maintenance,
    Carburant,
    AutoPartage,
    NSIM,
    Apollo,
    HelpIcon,
} from 'components/Icons';

import ModalChangePasswordContainer from '../../containers/Modals/ChangePasswordContainer';
import type { ReduxDispatch } from 'types';

import ReportsImage from 'assets/reports-white.png';
import GeofenceImage from 'assets/geofence.png';
import AdminImage from 'assets/gear-white.svg';
import OrganizationsImage from 'assets/organization-white.svg';
import { Action } from 'rxjs/scheduler/Action';
import { isMobile } from 'react-device-detect';
import {
    selectOrganization,
    selectOrganizationNotFormating,
} from '../../services/Organization/selectors';
import ActionRightSideMenu from '../ActionRightSideMenu/styles';
import Popover from 'antd/es/popover';
import './style.css';
type Props = {
    map: ?boolean,
};

type State = {
    showActionMenu: boolean,
    showAccount: boolean,
    showLanguage: boolean,
    showMainMenu: boolean,
    closeMainMenu: boolean,
    clickLocation: String,
    langModal: boolean,
};
const helpLinkFr =
    'https://learn.zohopublic.com/external/manual/base-de-connaissance-géothentic?p=f2a3f7e03fcc10532ec92e67e081262440c7e69488706b0bebdd227c0732a07c';
const helpLinkEn =
    'https://learn.zohopublic.com/external/manual/geothentics-knowledge-base?p=f2a3f7e03fcc10532ec92e67e08126244eb847c10e8b1ca988088eededc460b4';
const modalContent = (
    <div>
        <p>
            <FormattedMessage id={'components.welcome.modal.smwModal'} />
        </p>
    </div>
);
class ActionSideMenuComponent extends React.Component<Props, State> {
    state = {
        showActionMenu: false,
        showAccount: false,
        showLanguage: false,
        clickLocation: 'overview',
        langModal: false,
    };
    componentDidMount() {
        /* this.props.getLangUnitConfig().then((res) => {
            res.updated ? this.setState({ langModal: false }) : this.setState({ langModal: true })
        }
        )*/
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.props.isActiveBarOpen && !event.target.closest('#active-bar')) {
            this.props.hideActiveBar();
            this.setState({
                showAccount: false,
                showLanguage: false,
            });
        }
    };
    hideActivBarOnSelectMenu = () => {
        this.props.hideActiveBar();
        this.setState({
            showAccount: false,
            showLanguage: false,
        });
    };
    handleOnClickMenu = () => {
        this.setState((prevState) => ({ showActionMenu: !prevState.showActionMenu }));
    };
    handleOpenChange = (newOpen) => {
        this.setState({ langModal: newOpen });
    };
    hide = () => {
        this.setState({ langModal: false });
        this.props.changeLangUnitConfig(true);
    };
    handleOnClickOverview = () => {
        if (
            this.state.clickLocation === 'logo' ||
            this.state.clickLocation === 'overview' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
        }

        if (!this.props.location.pathname.includes('map')) {
            this.props.history.push(`/${this.props.locale}/map`);
        }
        this.setState({ clickLocation: 'overview' });
        this.hideActivBarOnSelectMenu();
    };

    handleOnClickReport = () => {
        if (
            this.state.clickLocation === 'report' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
        }

        if (!this.props.location.pathname.includes('report')) {
            this.props.history.push(`/${this.props.locale}/reports`);
        }

        this.setState({
            showActionMenu: true,
            clickLocation: 'report',
        });
        this.hideActivBarOnSelectMenu();
    };

    handleOnClickAdmin = () => {
        if (
            this.state.clickLocation === 'admin' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
        }

        if (!this.props.location.pathname.includes('admin')) {
            this.props.history.push(`/${this.props.locale}/admin`);
        }

        this.setState({
            showActionMenu: true,
            clickLocation: 'admin',
        });
        this.hideActivBarOnSelectMenu();
    };

    handleOnClickMaintenance = () => {
        this.props.hideSideMenu();
        // if (
        //     this.state.clickLocation === 'maintenance' ||
        //     this.state.clickLocation === '' ||
        //     !this.props.isOpen
        // ) {
        //     this.props.toggleSideMenu();
        // }

        if (!this.props.location.pathname.includes('maintenance')) {
            this.props.history.push(`/${this.props.locale}/maintenance/alarms`);
        }

        this.setState({
            showActionMenu: false,
            clickLocation: 'maintenance/alarm',
        });
        this.hideActivBarOnSelectMenu();
    };

    handleOnClickCarburant = () => {
        if (
            this.state.clickLocation === 'carburant' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
        }

        if (!this.props.location.pathname.includes('carburant')) {
            window.location.href = 'https://qsl-qc-ca-gir.klervi.net/';
        }

        this.setState({
            showActionMenu: true,
            clickLocation: 'carburant',
        });
        this.hideActivBarOnSelectMenu();
    };

    handleOnClickAutoPartage = () => {
        if (
            this.state.clickLocation === 'autoPartage' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
        }

        if (!this.props.location.pathname.includes('autoPartage')) {
            this.props.history.push(`/${this.props.locale}/autopartage`);
        }

        this.setState({
            showActionMenu: true,
            clickLocation: 'autoPartage',
        });
        this.hideActivBarOnSelectMenu();
    };

    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    handleToggleActiveBar = () => {
        this.setState({
            showAccount: false,
            showLanguage: false,
        });
        this.props.toggleActiveBar();
    };

    handleAccount = () => {
        this.setState({ langModal: false });
        if (this.props.isActiveBarOpen) {
            this.setState({
                showAccount: !this.state.showAccount,
            });
        } else {
            this.props.showActiveBar();
            this.setState({
                showAccount: true,
            });
        }
    };

    handleLogout = () => {
        this.props.logoutUser();
    };

    handleClickOrganizations = () => {
        this.props.history.push(`/${this.props.locale}/interne/orgs`);
    };

    handleClickUsers = () => {
        this.props.history.push(`/${this.props.locale}/interne/users`);
    };

    handleClickLogo = () => {
        if (
            this.state.clickLocation === 'logo' ||
            this.state.clickLocation === 'overview' ||
            this.state.clickLocation === '' ||
            !this.props.isOpen
        ) {
            this.props.toggleSideMenu();
            console.log(`toggle ${this.state.clickLocation} ${this.props.isOpen}`);
        }

        const url = this.props.isAdmin
            ? `/${this.props.locale}/interne/orgs`
            : `/${this.props.locale}/map`;
        this.props.history.push(url);

        this.setState({ clickLocation: 'logo' });
        this.props.hideActiveBar();
    };
    handleLanguage = () => {
        if (this.props.isActiveBarOpen) {
            this.setState({
                showLanguage: !this.state.showLanguage,
            });
        } else {
            this.props.showActiveBar();
            this.setState({
                showLanguage: true,
            });
        }
    };

    handleChangeLocale = (language) => {
        //this.props.changeLocale(language);
        this.props.changeLangUnitConfig(language);
    };

    handleChangeUnit = (unit) => {
        this.props.changeLangUnitConfig(unit);
        this.props.changeUnit(unit);
    };

    renderMainMenu = () => {
        const jwt = parseJWT(this.props.user.get('token'));
        return (
            <ActionSideMenu.AnimationContainer active={this.props.isOpen}>
                <ActionSideMenu.Animation>
                    <SideMenuComponent>
                        <SideMenuItem
                            source={OrganizationsImage}
                            onClick={this.handleOnClickOverview}
                            image={{
                                width: 32,
                                height: 60,
                            }}
                            height={60}
                            translationId={'components.SideMenu.Overview'}
                        />
                        {!isMobile ? (
                            <SideMenuItem
                                source={ReportsImage}
                                onClick={this.handleOnClickReport}
                                height={60}
                                image={{
                                    width: 22,
                                    height: 27,
                                }}
                                translationId={'components.SideMenu.Reports'}
                            />
                        ) : null}
                        {jwt.permissions &&
                        Array.isArray(jwt.permissions) &&
                        jwt.permissions.indexOf('read:vehicle-settings') != -1 &&
                        !isMobile ? (
                            <SideMenuItem
                                source={GeofenceImage}
                                onClick={this.handleOnClickGeofence}
                                height={60}
                                image={{
                                    width: 22,
                                    height: 27,
                                }}
                                translationId={'components.SideMenu.Geofences'}
                            />
                        ) : null}

                        {/** DISABLED START */}
                        <SideMenuItem
                            source={AdminImage}
                            height={60}
                            translationId={'components.SideMenu.Vehicles'}
                            disabled
                        />
                        <SideMenuItem
                            source={AdminImage}
                            height={60}
                            translationId={'components.SideMenu.Operators'}
                            disabled
                        />
                        <SideMenuItem
                            source={AdminImage}
                            height={60}
                            translationId={'components.SideMenu.Configuration'}
                            disabled
                        />
                        {/*<SideMenuItem
                            source={Maintenance}
                            height={60}
                            translationId={'components.SideMenu.Maintenance'}
                            disabled
                        />*/}
                        {/** DISABLED END */}
                    </SideMenuComponent>
                    <CloseButton
                        isOpen={this.props.isOpen}
                        onClick={this.handleToggleSideMenu}
                        offset={this.props.offset}
                        zIndex={5}
                    />
                </ActionSideMenu.Animation>
            </ActionSideMenu.AnimationContainer>
        );
    };

    renderActionMenu = () => {
        return (
            <ActionSideMenu.AnimationContainer active={this.props.isOpen}>
                <ActionSideMenu.Animation>{this.props.children}</ActionSideMenu.Animation>
            </ActionSideMenu.AnimationContainer>
        );
    };

    render() {
        const jwt = parseJWT(this.props.user.get('token'));
        const branchId = jwt['https://geothentic.com/branch_id'];
        return (
            <ActionSideMenu isOpen={this.props.isOpen}>
                <ActionSideMenu.ActionBar isOpen={this.props.isActiveBarOpen} id="active-bar">
                    <ActionSideMenu.Top>
                        <ActionSideMenu.Menu
                            menuType={`logo`}
                            onClick={this.handleClickLogo}
                            isOpen={this.props.isActiveBarOpen}
                        >
                            <LogoWhiteGreen isOpen={this.props.isActiveBarOpen} />
                        </ActionSideMenu.Menu>
                        <ActionSideMenu.Menu
                            menuType={`menu`}
                            isOpen={this.props.isActiveBarOpen}
                            onClick={this.props.toggleActiveBar}
                        >
                            <div>
                                <Menu />
                            </div>
                        </ActionSideMenu.Menu>
                        {this.props.isAdmin ? (
                            <React.Fragment>
                                <ActionSideMenu.Menu
                                    classType={`resize`}
                                    menuType={`overview`}
                                    isOpen={this.props.isActiveBarOpen}
                                    onClick={this.handleClickOrganizations}
                                >
                                    <div>
                                        <Building />
                                        <span>
                                            <FormattedMessage
                                                id={'components.SideMenu.Organizations'}
                                            />
                                        </span>
                                    </div>
                                </ActionSideMenu.Menu>
                                <ActionSideMenu.Menu
                                    classType={`resize`}
                                    menuType={`reports`}
                                    isOpen={this.props.isActiveBarOpen}
                                    onClick={this.handleClickUsers}
                                >
                                    <div>
                                        <User />
                                        <span>
                                            <FormattedMessage
                                                id={'components.SideMenuOrganization.Users'}
                                            />
                                        </span>
                                    </div>
                                </ActionSideMenu.Menu>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <ActionSideMenu.Menu
                                    classType={`resize`}
                                    menuType={`map`}
                                    isOpen={this.props.isActiveBarOpen}
                                    onClick={this.handleOnClickOverview}
                                >
                                    <div>
                                        <Map />
                                        <span>
                                            <FormattedMessage id={'components.SideMenu.Overview'} />
                                        </span>
                                    </div>
                                </ActionSideMenu.Menu>

                                {!isMobile ? (
                                    <ActionSideMenu.Menu
                                        classType={`resize`}
                                        menuType={`reports`}
                                        isOpen={this.props.isActiveBarOpen}
                                        onClick={this.handleOnClickReport}
                                    >
                                        <div>
                                            <Document />
                                            <span>
                                                <FormattedMessage
                                                    id={'components.SideMenu.Reports'}
                                                />
                                            </span>
                                        </div>
                                    </ActionSideMenu.Menu>
                                ) : null}
                                {jwt.permissions &&
                                Array.isArray(jwt.permissions) &&
                                (jwt.permissions.indexOf('write:branch-settings') !== -1 || //composante pour admin
                                    jwt.permissions.indexOf('access:admin') !== -1) &&
                                !isMobile ? (
                                    <ActionSideMenu.Menu
                                        classType={`resize`}
                                        menuType={`admin`}
                                        isOpen={this.props.isActiveBarOpen}
                                        onClick={this.handleOnClickAdmin}
                                    >
                                        <div>
                                            <Settings />
                                            <span>
                                                <FormattedMessage
                                                    id={'views.Admin.SideMenu.Admin'}
                                                />
                                            </span>
                                        </div>
                                    </ActionSideMenu.Menu>
                                ) : null}
                                {/*{jwt.permissions &&*/}
                                {/*Array.isArray(jwt.permissions) &&*/}
                                {/*(jwt.permissions.indexOf('write:branch-settings') !== -1 || //composante pour admin*/}
                                {/*    jwt.permissions.indexOf('own:branch') !== -1) &&*/}
                                {/*!isMobile ? (*/}

                                {/*    <ActionSideMenu.Menu*/}
                                {/*        classType={`resize`}*/}
                                {/*        menuType={`report`}*/}
                                {/*        isOpen={this.props.isActiveBarOpen}*/}
                                {/*        onClick={this.handleOnClickMaintenance}*/}
                                {/*    >*/}
                                {/*        <div>*/}
                                {/*            <Maintenance />*/}
                                {/*            <span>*/}
                                {/*    <FormattedMessage*/}
                                {/*        id={'components.SideMenu.Maintenance'}*/}
                                {/*    />*/}
                                {/*</span>*/}
                                {/*        </div>*/}
                                {/*    </ActionSideMenu.Menu>*/}

                                {/*    ) : null}*/}

                                {jwt.permissions &&
                                Array.isArray(jwt.permissions) &&
                                jwt.permissions.indexOf('read:branch-settings') !== -1 &&
                                !isMobile ? (
                                    <ActionSideMenu.Menu
                                        classType={`resize`}
                                        menuType={`report`}
                                        isOpen={this.props.isActiveBarOpen}
                                        onClick={this.handleOnClickMaintenance}
                                    >
                                        <div>
                                            <Maintenance />
                                            <span>
                                                <FormattedMessage
                                                    id={'components.SideMenu.Maintenance'}
                                                />
                                            </span>
                                        </div>
                                    </ActionSideMenu.Menu>
                                ) : null}

                                {this.getNSIMButton()}
                                {this.getCarburantButton()}
                                {branchId != 1863 && this.getApolloButton()}
                                {this.getAutoPartageButton()}
                            </React.Fragment>
                        )}

                        {/* <ActionSideMenu.Menu
                                classType={`resize`}
                                menuType={`usernetwork`}
                                isOpen={this.props.isActiveBarOpen}
                            >
                                <div>
                                    <UserNetwork />
                                    <span>Operators</span>
                                </div>
                            </ActionSideMenu.Menu>
                            <ActionSideMenu.Menu
                                classType={`resize`}
                                menuType={`truck`}
                                isOpen={this.props.isActiveBarOpen}
                            >
                                <div>
                                    <Truck />
                                    <span>Vehicles</span>
                                </div>
                            </ActionSideMenu.Menu>
                            <ActionSideMenu.Menu
                                classType={`resize`}
                                menuType={`checkmark`}
                                isOpen={this.props.isActiveBarOpen}
                            >
                                <div>
                                    <CheckMark />
                                    <span>Lorem Ipsum</span>
                                </div>
                            </ActionSideMenu.Menu> */}
                    </ActionSideMenu.Top>
                    <ActionSideMenu.Bottom>
                        {/* <ActionSideMenu.Menu
                                classType={`longer`}
                                menuType={`search`}
                                isOpen={this.props.isActiveBarOpen}
                            >
                                <div>
                                    <Search />
                                    <span>Search</span>
                                </div>
                            </ActionSideMenu.Menu>
                            <ActionSideMenu.Menu
                                classType={`longer`}
                                menuType={`notification`}
                                isOpen={this.props.isActiveBarOpen}
                            >
                                <div>
                                    <Notification />
                                    <i>3</i>
                                    <span>Notifications</span>
                                </div>
                            </ActionSideMenu.Menu> */}

                        <ActionSideMenu.Help>
                            <a
                                href={this.props.locale == 'fr' ? helpLinkFr : helpLinkEn}
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                            >
                                <div
                                    style={{
                                        marginLeft: '12px',
                                        marginRight: '12px',
                                        width: '100px',
                                    }}
                                >
                                    <HelpIcon />
                                    {/*Deconnexion*/}
                                    <span
                                        style={{
                                            marginLeft: '15px',
                                            color: 'white',
                                            fontFamily: 'OpenSans',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {this.props.isActiveBarOpen ? (
                                            <FormattedMessage id={'components.SideMenu.Help'} />
                                        ) : null}
                                    </span>
                                </div>
                            </a>
                        </ActionSideMenu.Help>
                        <ActionSideMenu.MenuDropDown>
                            {this.state.langModal ? (
                                <Popover
                                    placement="rightBottom"
                                    title={<a onClick={this.hide}>&#x2715;</a>}
                                    content={modalContent}
                                    open={this.state.langModal}
                                    onOpenChange={this.handleOpenChange}
                                >
                                    <ActionSideMenu.Menu
                                        classType={`longer`}
                                        menuType={`world`}
                                        isOpen={this.props.isActiveBarOpen}
                                        onClick={this.handleAccount}
                                    >
                                        <div>
                                            <User />
                                            <span>
                                                {/*Profil*/}
                                                <FormattedMessage
                                                    id={'components.SideMenu.Account'}
                                                />
                                            </span>
                                        </div>
                                    </ActionSideMenu.Menu>
                                </Popover>
                            ) : (
                                <ActionSideMenu.Menu
                                    classType={`longer`}
                                    menuType={`world`}
                                    isOpen={this.props.isActiveBarOpen}
                                    onClick={this.handleAccount}
                                >
                                    <div>
                                        <User />
                                        <span>
                                            {/*Profil*/}
                                            <FormattedMessage id={'components.SideMenu.Account'} />
                                        </span>
                                    </div>
                                </ActionSideMenu.Menu>
                            )}
                            <ActionSideMenu.MenuDropDownContent
                                isExpanded={this.state.showAccount}
                                options={2}
                            >
                                {/* <ActionSideMenu.Menu
                                    classType={`longer`}
                                    menuType={`user`}
                                    isOpen={this.props.isActiveBarOpen}
                                    onClick={this.handleAccount}
                                >
                                    <div>
                                        <Gear />
                                        <span>
                                            <FormattedMessage id={'components.SideMenu.Settings'} />
                                        </span>
                                    </div>
                                </ActionSideMenu.Menu> */}
                                <ActionSideMenu.Menu classType={`dropdown`} menuType={`world`}>
                                    <div>
                                        <SelectField
                                            menuPlacement={'top'}
                                            placeholder={'unit'}
                                            options={[
                                                {
                                                    label: this.props.intl.formatMessage({
                                                        id: 'views.Login.Distance.Imperial',
                                                    }),
                                                    value: 'imperial',
                                                },
                                                {
                                                    label: this.props.intl.formatMessage({
                                                        id: 'views.Login.Distance.Metric',
                                                    }),
                                                    value: 'metric',
                                                },
                                            ]}
                                            value={this.props.unit}
                                            borderColor={colors.grayDD}
                                            textColor={colors.black}
                                            onChange={this.handleChangeUnit}
                                            padding={'2px 0 0 12px'}
                                        />
                                    </div>
                                </ActionSideMenu.Menu>
                                <ActionSideMenu.Menu classType={`dropdown`} menuType={`world`}>
                                    <div>
                                        {/*Langues*/}
                                        <SelectField
                                            menuPlacement={'top'}
                                            placeholder={this.props.intl.formatMessage({
                                                id: 'components.SideMenu.Languages',
                                            })}
                                            options={[
                                                {
                                                    label: this.props.intl.formatMessage({
                                                        id: 'components.Header.Locale.English',
                                                    }),
                                                    value: 'en',
                                                },
                                                {
                                                    label: this.props.intl.formatMessage({
                                                        id: 'components.Header.Locale.French',
                                                    }),
                                                    value: 'fr',
                                                },
                                                {
                                                    label: this.props.intl.formatMessage({
                                                        id: 'components.Header.Locale.Spanish',
                                                    }),
                                                    value: 'es',
                                                },
                                            ]}
                                            value={this.props.locale}
                                            borderColor={colors.grayDD}
                                            textColor={colors.black}
                                            onChange={this.handleChangeLocale}
                                            padding={'2px 0 0 12px'}
                                        />
                                    </div>
                                </ActionSideMenu.Menu>
                            </ActionSideMenu.MenuDropDownContent>
                        </ActionSideMenu.MenuDropDown>

                        <ActionSideMenu.Menu
                            classType={`longer`}
                            menuType={`user`}
                            isOpen={this.props.isActiveBarOpen}
                            onClick={this.handleLogout}
                        >
                            <div>
                                <ArrowLeaving />
                                {/*Deconnexion*/}
                                <span>
                                    <FormattedMessage id={'components.SideMenu.Logout'} />
                                </span>
                            </div>
                        </ActionSideMenu.Menu>
                    </ActionSideMenu.Bottom>
                </ActionSideMenu.ActionBar>
                <ActionSideMenu.ActionBarClose
                    loggedInAsUser={this.props.loggedInAsUser}
                    isOpen={this.props.isActiveBarOpen}
                    onClick={this.handleToggleActiveBar}
                >
                    <ArrowLeft />
                </ActionSideMenu.ActionBarClose>
                {this.props.location.pathname.includes('maintenance') ||
                this.props.location.pathname.includes('autopartage') ? null : (
                    <ActionSideMenu.SideMenuContainer
                        isOpen={this.props.isOpen}
                        map={this.props.map}
                    >
                        {/* {this.renderMainMenu()} */}
                        {this.renderActionMenu()}
                    </ActionSideMenu.SideMenuContainer>
                )}

                {this.props.modalType === 'MODAL_TYPE_CHANGEPASSWORD' && (
                    <ModalChangePasswordContainer />
                )}
            </ActionSideMenu>
        );
    }
    getApolloButton() {
        return (
            <React.Fragment>
                <ActionSideMenu.Menu
                    classType={`resize`}
                    menuType={`report`}
                    isOpen={this.props.isActiveBarOpen}
                    onClick={() => {
                        const url = 'https://eld.azuga.com/portal/login.html';
                        const newWindow = window.open(url, 'routes');
                        if (newWindow) newWindow.opener = null;
                    }}
                >
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Apollo />
                        <span>
                            <FormattedMessage id={'components.SideMenu.ELOG'} />
                        </span>
                    </div>
                </ActionSideMenu.Menu>
            </React.Fragment>
        );
    }

    getCarburantButton() {
        if (!this.props.user || !this.props.organization) {
            return null;
        }
        const jwt = parseJWT(this.props.user.get('token'));

        if (
            !jwt.permissions ||
            !Array.isArray(jwt.permissions) ||
            jwt.permissions.indexOf('access:fuel-management') === -1
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <ActionSideMenu.Menu
                    classType={`resize`}
                    menuType={`report`}
                    isOpen={this.props.isActiveBarOpen}
                    onClick={() => {
                        const urlQsl = 'https://qsl-qc-ca-gir.klervi.net/';
                        const urlPolycor = 'https://polycor-qc-ca-gir.klervi.net/';
                        const newWindow = window.open(
                            this.props.organization.id === 1694
                                ? urlQsl
                                : this.props.organization.id === 2622
                                    ? urlPolycor
                                    : urlQsl,
                            'routes'
                        );
                        if (newWindow) newWindow.opener = null;
                    }}
                >
                    <div>
                        <Carburant />
                        <span style={{ fontSize: '10px' }}>
                            <FormattedMessage id={'components.SideMenu.Carburant'} />
                        </span>
                    </div>
                </ActionSideMenu.Menu>
            </React.Fragment>
        );
    }

    getNSIMButton() {
        if (!this.props.user || !this.props.organization) {
            return null;
        }
        const jwt = parseJWT(this.props.user.get('token'));
        const apiCredentials = this.props.organization['api_credentials'];

        if (
            !jwt.permissions ||
            !Array.isArray(jwt.permissions) ||
            jwt.permissions.indexOf('access:predefined-routes-nsim') === -1
        ) {
            return null;
        }
        if (!apiCredentials || !apiCredentials['credentials']) {
            return null;
        }
        const nsim_data = apiCredentials['credentials'].filter((i) => i['service'] === 'NSIM')[0];
        if (!nsim_data || !nsim_data.nsim_url) {
            return null;
        }

        return (
            <React.Fragment>
                <ActionSideMenu.Menu
                    classType={`resize`}
                    menuType={`report`}
                    isOpen={this.props.isActiveBarOpen}
                    onClick={() => {
                        const url = nsim_data.nsim_url;
                        const newWindow = window.open(url, 'routes');
                        if (newWindow) newWindow.opener = null;
                    }}
                >
                    <div>
                        <NSIM />
                        <span>
                            <FormattedMessage id={'components.SideMenu.Rasters'} />
                        </span>
                    </div>
                </ActionSideMenu.Menu>
            </React.Fragment>
        );
    }
    getAutoPartageButton() {
        if (!this.props.user || !this.props.organization) {
            return null;
        }
        const jwt = parseJWT(this.props.user.get('token'));

        if (
            !jwt.permissions ||
            !Array.isArray(jwt.permissions) ||
            jwt.permissions.indexOf('access:carshare') === -1
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <ActionSideMenu.Menu
                    classType={`resize`}
                    menuType={`report`}
                    isOpen={this.props.isActiveBarOpen}
                    onClick={this.handleOnClickAutoPartage}
                >
                    <div>
                        <AutoPartage />
                        <span>
                            <FormattedMessage id={'components.SideMenu.AutoPartage'} />
                        </span>
                    </div>
                </ActionSideMenu.Menu>
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    unit: selectUnit(),
    isOpen: selectIsSideMenuOpen(),
    isActiveBarOpen: selectIsActiveBarOpen(),
    isFleetOverviewOpen: selectIsFleetOverviewOpen(),
    showModal: selectShowModal(),
    modalType: selectModalType(),
    modalError: selectModalError(),
    loggedInAsUser: selectLoggedInAsUser(),
    organization: selectOrganizationNotFormating(),
    user: selectUser(),
    langUnitConfigured: selectIsLangUnitConfigured(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            changeLocale,
            changeUnit,
            logoutUser,
            toggleSideMenu,
            showSideMenu,
            hideSideMenu,
            toggleActiveBar,
            showActiveBar,
            hideActiveBar,
            showFleetOverview,
            changeLangUnitConfig,
            hideFleetOverview,
            toggleChangePasswordModal,
            changeUserPassword,
            closeModal,
            getLangUnitConfig,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ActionSideMenuComponent))
);
