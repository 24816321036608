// @flow

import { createSelector } from 'reselect';

const selectAutoReportsState = () => (state) => state.get('automaticReport');

const selectAutoReportsList = () =>
    createSelector(selectAutoReportsState(), (substate) => substate.get('automaticReportList'));

const selectorsDeleteAutomaticReportItem = () =>
    createSelector(selectAutoReportsState(), (substate) =>
        substate.get('selectorsDeleteAutomaticReportItem')
    );

const selectorsPostAutomaticReportItem = () =>
    createSelector(selectAutoReportsState(), (substate) =>
        substate.get('selectorsPostAutomaticReportItem')
    );

export {
    selectAutoReportsList,
    selectorsDeleteAutomaticReportItem,
    selectorsPostAutomaticReportItem,
};
