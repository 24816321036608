exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);", ""]);

// module
exports.push([module.id, ".ap-links {\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 18px;\n  letter-spacing: -0.5px;\n}\n\n.MuiContainer-root.ap-nav-bar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 0.1rem solid #D8D8D8;\n  margin-bottom: 15px;\n  margin-top: 12px;\n  padding-bottom: 17px;\n  padding-right: 0;\n  padding-left: 0;\n  max-width: inherit;\n}\n\n.ap-reservation-button {\n  border-radius: 10px;\n  padding: 8px;\n}\n.ap-reservation-button:hover {\n  background-color: #528F2D !important;\n}\n\n.ap-reservation-text {\n  margin-left: 4px;\n  text-transform: capitalize;\n}", ""]);

// exports
