import adminRequest from 'services/adminRequest';

export function retrieveAutomaticReports(token, id) {
    const url = `reports/automatic-reports/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function resourcesDeleteAutomaticReportItem(token, id) {
    const url = `reports/automatic-reports/${id}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        false,
        token
    );
}

export function resourcesPostAutomaticReportItem(token, body) {
    const url = `reports/automatic-reports`;
    console.log('111111111', body);
    return adminRequest(
        url,
        {
            method: 'POST',
            data: JSON.stringify(body),
        },
        true,
        token
    );
}
