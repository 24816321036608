// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import GenerateReportHeader from 'containers/ReportGenerator/Header';
import { Split } from 'styles/common';
import colors from 'styles/colors';
import './dashboard.css';
import moment from 'moment';
import { i18nDistanceUnit, changeUnit, i18nDistanceSymbol } from 'services/Units/thunks';
import { selectUnit } from 'services/Units/selectors';

import Equipment from 'assets/equipment-sidemenu-black.svg';
import Calendar from 'assets/calendar-black.svg';

import { ReportGeneratorContainer, Report } from 'styles/reports';

// Components
import GeoHBarChart from 'components/GeoHBarChart';
import GeoVBarChart from 'components/GeoVBarChart';
import GeoPieChart from 'components/GeoPieChart';
import EngineErrorChart from 'components/EngineErrorChart';
import Loader from 'components/Loader';
import ReportsInputDate from 'components/ReportsInputDate';
// Selectors
import {
    selectDashboardReportSummary,
    selectDashboardRealtimesSummary,
    selectIsLoadingDashboardReportSummary,
    selectIsLoadingDashboardRealtimesSummary,
    selectIsLoadingDashboardGeofenceSummary,
    selectIsLoadingDashboardRegenerationSummary,
    selectIsLoadingDashboardInstantSummary,
    selectDashboardGeofenceSummary,
    selectDashboardRegenerationSummary,
    selectDashboardInstantSummary,
} from 'services/Report/selectors';
import { selectVehicles } from 'services/Vehicle/selectors';
// Thunks
import {
    generateDashboardReportSummary,
    generateDashboardRealtimesSummary,
    generateDashboardGeofenceSummary,
    generateDashboardRegenerationSummary,
    generateDashboardInstantSummary,
} from 'services/Report/thunks';
import { getVehicles } from 'services/Vehicle/thunks';
import {
    getHistoryModification,
    getHistoryModificationByBranchId,
} from '../../../services/Report/thunks';
import { selectUser } from '../../../services/Authentication/selectors';
import { parseJWT } from '../../../helpers';
import dateFns from 'date-fns';

const ReportPageComponent = (props) => {
    const {
        generateDashboardReportSummary,
        generateDashboardRealtimesSummary,
        generateDashboardGeofenceSummary,
        generateDashboardRegenerationSummary,
        generateDashboardInstantSummary,
        dashboardReportSummary,
        dashboardRealtimesSummary,
        dashboardGeofenceSummary,
        dashboardRegenerationSummary,
        dashboardInstantSummary,
        intl,
        report,
        loadingDashboardReportSummary,
        loadingDashboardRealtimesSummary,
        loadingDashboardGeofenceSummary,
        loadingDashboardRegenerationSummary,
        loadingDashboardInstantSummary,
        i18nDistanceUnit,
        i18nDistanceSymbol,
        changeUnit,
        unit,
        vehicles,
        getVehicles,
    } = props;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [reportSummaryData, setReportSummaryData] = useState([]);
    const [realtimesSummaryData, setRealtimesSummaryData] = useState([]);
    const [geofenceSummaryData, setGeofenceSummaryData] = useState([]);
    const [regenerationSummaryData, setRegenerationSummaryData] = useState([]);
    const [instantSummaryData, setInstantSummaryData] = useState([]);
    const [instantExcelData, setInstantExcelData] = useState([]);
    const [periodFrom, setPeriodFrom] = useState(
        dateFns.format(dateFns.startOfDay(dateFns.subDays(new Date(), 7)))
    );
    const [periodTo, setPeriodTo] = useState(dateFns.format(dateFns.endOfDay(new Date())));
    const [dateLimit, setDateLimit] = useState(false);
    const [dateLimitPositionLeft, setDateLimitPositionLeft] = useState(true);
    const [dateRange, setDateRange] = useState(
        `${intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${moment(periodFrom)
            //.utc()
            .format('YYYY-MM-DD')
            .toString()} ${intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${moment(periodTo)
            //.utc()
            .format('YYYY-MM-DD')
            .toString()}`
    );

    const [fleetTotalDistance, setFleetTotalDistance] = useState(0);
    const [fleetTotalIdling, setFleetTotalIdling] = useState(0);
    const [fleetTotalUtilisationTime, setFleetTotalUtilisationTime] = useState(0);
    const [fleetCount, setFleetCount] = useState(0);
    const [vehicleReportCount, setVehicleReportCount] = useState(0);
    const [fleetTotalPersonal, setFleetTotalPersonal] = useState(0);
    const [fleetTotalWork, setFleetTotalWork] = useState(0);
    const [historyArray, setHistoryArray] = useState([]);
    const [sortedHistArray, setSortedHistArray] = useState([]);
    const jwt = parseJWT(props.user.get('token'));
    const branchId = jwt['https://geothentic.com/branch_id'];
    const [vehiclesErrorCodes, setVehicleErrorCodes] = useState([]);

    const [last24hActivity, setLast24hActivity] = useState([]);
    const [last72hActivity, setLast72hActivity] = useState([]);
    const [noActivity, setNoActivity] = useState([]);

    const currentDate = new Date();
    const currentTimeInSeconds = Math.floor(currentDate.getTime() / 1000);

    const getLastUpdateActivity = (vehicleDate) => {
        const timeDifference = currentTimeInSeconds - vehicleDate.last_update;
        let data = {};

        if (timeDifference < 86400) {
            data = {
                label: intl.formatMessage({
                    id: 'views.Reports.SideMenu.Dashboard.RealtimeConnected.today',
                }),
                lastUpdate: '24hData',
            };
        } else if (timeDifference < 259200) {
            data = {
                label: intl.formatMessage({
                    id: 'views.Reports.SideMenu.Dashboard.RealtimeConnected.under_3_days',
                }),
                lastUpdate: '72hData',
            };
        } else {
            data = {
                label: intl.formatMessage({
                    id: 'views.Reports.SideMenu.Dashboard.RealtimeConnected.over_3_days',
                }),
                lastUpdate: 'noData',
            };
        }
        return data;
    };

    useEffect(
        () => {
            generateDashboardReportSummary(
                moment(periodFrom)
                    .utc()
                    .format(),
                moment(periodTo)
                    .utc()
                    .format()
            );
            generateDashboardRealtimesSummary(
                moment(periodFrom)
                    .utc()
                    .format(),
                moment(periodTo)
                    .utc()
                    .format()
            );
            generateDashboardGeofenceSummary(
                moment(periodFrom)
                    .utc()
                    .format(),
                moment(periodTo)
                    .utc()
                    .format()
            );
            generateDashboardRegenerationSummary(
                moment(periodFrom)
                    .utc()
                    .format(),
                moment(periodTo)
                    .utc()
                    .format()
            );
            generateDashboardInstantSummary();
            setIsLoaded(true);
            getVehicles();
            setDateRange(
                `${intl.formatMessage({
                    id: 'containers.Reports.Generators.From',
                })} ${moment(periodFrom)
                    //.utc()
                    .format('YYYY-MM-DD')
                    .toString()} ${intl.formatMessage({
                    id: 'containers.Reports.Generators.To',
                })} ${moment(periodTo)
                    //.utc()
                    .format('YYYY-MM-DD')
                    .toString()}`
            );
        },
        [periodFrom, periodTo]
    );
    useEffect(
        () => {
            if (vehicles.toJS().length > 0) {
                const vehiclesWithEcu = vehicles
                    .toJS()
                    .map((veh) => {
                        const vehicle = {};
                        vehicle.name = veh.name;
                        if (veh.lastEcuData) {
                            const lastErrorCode = veh.lastEcuData.IDS.find(
                                (code) => code.id === 10002 && code.errorCodes.length > 0
                            );
                            if (lastErrorCode) {
                                vehicle.lastErrorCode = lastErrorCode.errorCodes[0];
                                vehicle.occurence = lastErrorCode.errorCodes[0].occurenceCount;
                                vehicle.suspect = lastErrorCode.errorCodes[0].suspect;
                                vehicle.fmi = lastErrorCode.errorCodes[0].fmi;
                            } else {
                                vehicle.lastErrorCode = null;
                            }
                        } else {
                            vehicle.lastErrorCode = null;
                        }
                        return vehicle;
                    })
                    .filter((veh) => veh.lastErrorCode);
                setVehicleErrorCodes(vehiclesWithEcu);
            }
        },
        [vehicles]
    );

    useEffect(
        () => {
            // Refresh reports to match imperial or metric units
            generateDashboardReportSummary(
                moment(periodFrom)
                    .utc()
                    .format(),
                moment(periodTo)
                    .utc()
                    .format()
            );
            setIsLoaded(true);
        },
        [unit, periodFrom, periodTo]
    );

    useEffect(
        () => {
            if (
                dashboardReportSummary &&
                dashboardReportSummary.get('Dashboard_summary') &&
                dashboardReportSummary.get('Dashboard_summary').size > 0
            ) {
                setReportSummaryData(dashboardReportSummary.get('Dashboard_summary').toJS());
                setFleetTotalDistance(dashboardReportSummary.get('Fleet_total_distance'));
                setFleetTotalIdling(dashboardReportSummary.get('Fleet_total_idling'));
                setFleetTotalUtilisationTime(
                    dashboardReportSummary.get('Fleet_total_utilisation_time')
                );
                setFleetTotalPersonal(
                    parseInt(dashboardReportSummary.get('Fleet_percentage_personal_mode'))
                );
                setFleetTotalWork(
                    parseInt(dashboardReportSummary.get('Fleet_percentage_work_mode'))
                );
                setVehicleReportCount(parseInt(dashboardReportSummary.get('Fleet_vehicle_count')));
            }
        },
        [dashboardReportSummary, periodFrom, periodTo]
    );

    useEffect(
        () => {
            if (
                dashboardRealtimesSummary &&
                dashboardRealtimesSummary.get('Dashboard_summary') &&
                dashboardRealtimesSummary.get('Dashboard_summary').size > 0
            ) {
                setRealtimesSummaryData(dashboardRealtimesSummary.get('Dashboard_summary').toJS());
            }
        },
        [dashboardRealtimesSummary, periodFrom, periodTo]
    );

    useEffect(
        () => {
            if (
                dashboardGeofenceSummary &&
                dashboardGeofenceSummary.get('Dashboard_summary') &&
                dashboardGeofenceSummary.get('Dashboard_summary').size > 0
            ) {
                setGeofenceSummaryData(dashboardGeofenceSummary.get('Dashboard_summary').toJS());
            }
        },
        [dashboardGeofenceSummary, periodFrom, periodTo]
    );

    useEffect(
        () => {
            if (
                dashboardRegenerationSummary &&
                dashboardRegenerationSummary.get('Dashboard_summary') &&
                dashboardRegenerationSummary.get('Dashboard_summary').size > 0
            ) {
                setRegenerationSummaryData(
                    dashboardRegenerationSummary.get('Dashboard_summary').toJS()
                );
            }
        },
        [dashboardRegenerationSummary, periodFrom, periodTo]
    );

    useEffect(
        () => {
            if (
                dashboardInstantSummary &&
                dashboardInstantSummary.get('Dashboard_summary') &&
                dashboardInstantSummary.get('Dashboard_summary').size > 0
            ) {
                setInstantSummaryData(dashboardInstantSummary.get('Dashboard_summary').toJS());
                setFleetCount(
                    dashboardInstantSummary.getIn(['Dashboard_summary', 'fleet_vehicles'])
                );
            }

            if (
                dashboardInstantSummary &&
                dashboardInstantSummary.get('data') &&
                dashboardInstantSummary.get('data').size > 0
            ) {
                const startDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                ).toISOString();

                const endDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                ).toISOString();

                const data = {
                    modificationTypeIds: [1],
                    startDate,
                    endDate,
                };

                const tab2 = [];
                const tab = { user: '', date_changed: '' };
                let tempLast24hActivity = 0;
                let tempLast72hActivity = 0;
                let tempLastnoDataActivity = 0;

                dashboardInstantSummary
                    .get('data')
                    .toJS()
                    .map((item) => {
                        if (getLastUpdateActivity(item).lastUpdate === '24hData') {
                            tempLast24hActivity++;
                        }
                        if (getLastUpdateActivity(item).lastUpdate === '72hData') {
                            tempLast72hActivity++;
                        }
                        if (getLastUpdateActivity(item).lastUpdate === 'noData') {
                            tempLastnoDataActivity++;
                        }
                        tab2.push({ ...item, ...tab });
                    });
                setLast24hActivity(tempLast24hActivity);
                setLast72hActivity(tempLast72hActivity);
                setNoActivity(tempLastnoDataActivity);

                setInstantExcelData(tab2);
                props.getHistoryModificationByBranchId(branchId, data).then(async (response) => {
                    setHistoryArray(response);
                });
            }
        },
        [dashboardInstantSummary, periodFrom, periodTo]
    );

    const convertLastUpdate = (date) => {
        const LastActiveDate = new Date(date * 1000);
        const dateYear = LastActiveDate.getFullYear();
        const dateMonth = `${LastActiveDate.getMonth() + 1}`.padStart(2, '0');
        const dateDay = `${LastActiveDate.getDate()}`.padStart(2, '0');
        const dateHours = `${LastActiveDate.getHours()}`.padStart(2, '0');
        const dateMinutes = `${LastActiveDate.getMinutes()}`.padStart(2, '0');
        const dateSeconds = `${LastActiveDate.getSeconds()}`.padStart(2, '0');
        return `${dateYear}-${dateMonth}-${dateDay} ${dateHours}:${dateMinutes}:${dateSeconds}`;
    };

    const convertToLocalTime = (isoString) => {
        const localDate = new Date(isoString);
        const dateYear = localDate.getFullYear();
        const dateMonth = `${localDate.getMonth() + 1}`.padStart(2, '0');
        const dateDay = `${localDate.getDate()}`.padStart(2, '0');
        const dateHours = `${localDate.getHours()}`.padStart(2, '0');
        const dateMinutes = `${localDate.getMinutes()}`.padStart(2, '0');
        const dateSeconds = `${localDate.getSeconds()}`.padStart(2, '0');
        return `${dateYear}-${dateMonth}-${dateDay} ${dateHours}:${dateMinutes}:${dateSeconds}`;
    };

    useEffect(
        () => {
            if (historyArray) {
                if (historyArray.length > 0) {
                    const tab = [];
                    historyArray.map((historyArray) => {
                        // date après le triage du tableau
                        const modifDate =
                            historyArray.modificationsArray[
                                historyArray.modificationsArray.length - 1
                            ].modification.modificationDate;
                        let date = new Date(modifDate);
                        date = date.setHours(date.getHours() - 4);
                        // nom du user après le triage
                        const userObject =
                            historyArray.modificationsArray[
                                historyArray.modificationsArray.length - 1
                            ].user;
                        const userName = `${userObject.firstanName} ${userObject.lastName}`;
                        tab.push({
                            vehId: historyArray.vehicleId,
                            modifDate: moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss'),
                            user: userName,
                        });
                    });
                    setSortedHistArray(tab);
                }
            }
        },
        [historyArray]
    );

    useEffect(
        () => {
            const tab = [...instantExcelData];
            sortedHistArray.map((sortedData) => {
                instantExcelData.map((excelData, index) => {
                    if (sortedData.vehId === excelData.vehicle_id) {
                        tab[index] = {
                            ...excelData,
                            user: sortedData.user,
                            date_changed: sortedData.modifDate,
                        };
                    }
                });
            });

            setInstantExcelData(tab);
        },
        [sortedHistArray]
    );
    useEffect(
        () => {
            if (vehicles) {
                const vehErrArray = vehicles.map((veh) => {
                    const vehicule = {};
                });
            }
        },
        [vehicles]
    );

    const handleOnChangePeriod = (key: string) => (event: Event) => {
        const date = event.target.value;

        let dateToChange;
        if (key === 'periodFrom') {
            dateToChange = moment(date)
                .startOf('day')
                .format();
        } else if (key === 'periodTo') {
            dateToChange = moment(date)
                .endOf('day')
                .format();
        }

        changePeriod(key, dateToChange);
    };

    const today = dateFns.format(new Date());
    const changePeriod = (key: string, date: string) => {
        if (dateLimit) {
            setDateLimit(false);
        }

        if (key === 'periodFrom' && dateFns.differenceInDays(periodTo, date) > 366) {
            const newDateTo = dateFns.addDays(date, 366);
            const formattedNewDate = dateFns.format(newDateTo);
            setDateLimit(true);
            setPeriodTo(formattedNewDate);
            setDateLimitPositionLeft(true);
        } else if (key === 'periodTo' && dateFns.differenceInDays(date, periodFrom) > 366) {
            const newDateFrom = dateFns.subDays(date, 366);
            const formattedNewDateFrom = dateFns.format(newDateFrom);
            setDateLimit(true);
            setPeriodFrom(formattedNewDateFrom);
            setDateLimitPositionLeft(false);
        }

        let dateToChange = date;
        const dateCompare =
            key === 'periodFrom'
                ? dateFns.compareAsc(date, periodTo)
                : dateFns.compareAsc(periodFrom, date);
        const todayCompare = dateFns.compareAsc(date, today);
        if (todayCompare === 1) {
            dateToChange = today;
        }

        if (key === 'periodFrom') {
            setPeriodFrom(dateToChange);
        } else {
            setPeriodTo(dateToChange);
        }
    };
    const renderDashboardBody = () => {
        const COLORS = [
            colors.bleu1F,
            colors.green61,
            colors.yellowDE,
            colors.red,
            colors.pink,
            colors.orange,
        ];

        if (error) {
            console.log(error.message);
        } else if (!isLoaded) {
            return <Loader true />;
        } else {
            return [
                <div>
                    <React.Fragment>
                        <Report.TextContainer>
                            <Report.Icon
                                src={Equipment}
                                width={26}
                                height={26}
                                margin={'0 9px 0 0'}
                            />
                            <span className="d-inline-block">
                                <FormattedMessage id={'components.SideMenu.FleetCount'} />
                            </span>
                            <span style={{ padding: '2%' }}>{fleetCount}</span>
                        </Report.TextContainer>
                        <Report.TextContainer>
                            <Report.Icon
                                src={Calendar}
                                width={26}
                                height={26}
                                margin={'0 9px 0 0'}
                            />
                            {dateRange}
                        </Report.TextContainer>
                    </React.Fragment>
                </div>,
                <div className={'report_container'}>
                    <div className="layoutRoot">
                        {/* Use of Vehicles */}
                        <GeoPieChart
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.RealtimeConnected.mode',
                            })}
                            dateRange={dateRange}
                            loading={loadingDashboardInstantSummary}
                            data={[
                                {
                                    id: instantSummaryData.count_garage_mode,
                                    type: 'square',
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.RealtimeConnected.today',
                                    }),
                                    value: last24hActivity,
                                    fill: colors.green61,
                                },
                                {
                                    id: instantSummaryData.count_garage_mode,
                                    type: 'square',
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.RealtimeConnected.under_3_days',
                                    }),
                                    value: last72hActivity,
                                    fill: colors.yellowDE,
                                },
                                {
                                    id: instantSummaryData.count_garage_mode,
                                    type: 'square',
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.RealtimeConnected.over_3_days',
                                    }),
                                    value: noActivity,
                                    fill: colors.red,
                                },
                            ]}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id: 'components.Modals.Form.Config.GestionCond.lastUse',
                                    }),
                                    barName: 'last_connection',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.RealtimeConnected.Timestamp',
                                    }),
                                    barName: 'last_update',
                                },
                            ]}
                            dataExcel={instantExcelData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    last_connection: getLastUpdateActivity(element).label,
                                    last_update: element.last_update
                                        ? convertLastUpdate(element.last_update)
                                        : '',
                                }))
                                .sort((a, b) => {
                                    const nameA = a.last_connection;
                                    const nameB = b.last_connection;
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }
                                    return 0;
                                })}
                            dataKey={'value'}
                        />
                        <GeoPieChart
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.garageMode.mode',
                            })}
                            dateRange={dateRange}
                            loading={loadingDashboardInstantSummary}
                            data={[
                                {
                                    id:
                                        instantSummaryData.fleet_vehicles -
                                        instantSummaryData.count_garage_mode,
                                    type: 'square',
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.nonGarageMode',
                                    }),
                                    value:
                                        instantSummaryData.fleet_vehicles -
                                        instantSummaryData.count_garage_mode,
                                    fill: colors.green61,
                                },
                                {
                                    id: instantSummaryData.count_garage_mode,
                                    type: 'square',
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.garageMode',
                                    }),
                                    value: instantSummaryData.count_garage_mode,
                                    fill: colors.yellowDE,
                                },
                            ]}
                            dataKey={'value'}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.garageMode.mode',
                                    }),
                                    barName: 'is_garage_mode',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Excel.userName',
                                    }),
                                    barName: 'user',
                                },
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Excel.dateChanged',
                                    }),
                                    barName: 'date_changed',
                                },
                            ]}
                            dataExcel={instantExcelData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    is_garage_mode: element.is_garage_mode
                                        ? intl.formatMessage({
                                              id: 'views.Reports.SideMenu.Dashboard.garageMode',
                                          })
                                        : intl.formatMessage({
                                              id: 'views.Reports.SideMenu.Dashboard.nonGarageMode',
                                          }),
                                    user: element.user,
                                    date_changed: element.date_changed,
                                }))
                                .sort((a, b) => {
                                    const nameA = a.is_garage_mode;
                                    const nameB = b.is_garage_mode;
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }
                                    return 0;
                                })}
                        />
                        <GeoVBarChart
                            loading={loadingDashboardRealtimesSummary}
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Comportement.mode',
                            })}
                            dateRange={dateRange}
                            data={realtimesSummaryData.sort(
                                (a, b) =>
                                    b.total_fast_acceleration +
                                    b.total_fast_deceleration +
                                    b.total_impact_event -
                                    (a.total_fast_acceleration +
                                        a.total_fast_deceleration +
                                        a.total_impact_event)
                            )}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.Comportement.harshAcceleration',
                                    }),
                                    barName: 'total_fast_acceleration',
                                    barStackId: 'a',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: intl.formatMessage({
                                        id:
                                            'views.Reports.SideMenu.Dashboard.Comportement.harshBraking',
                                    }),
                                    barName: 'total_fast_deceleration',
                                    barStackId: 'b',
                                    stroke: colors.pink,
                                    fill: colors.pink,
                                },
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Comportement.impact',
                                    }),
                                    barName: 'total_impact_event',
                                    barStackId: 'c',
                                    stroke: colors.yellowDE,
                                    fill: colors.yellowDE,
                                },
                            ]}
                        />
                        <GeoHBarChart
                            loading={loadingDashboardReportSummary}
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Distance.mode',
                            })}
                            dateRange={dateRange}
                            data={reportSummaryData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    total_distance_below_average: Number(
                                        i18nDistanceUnit(
                                            element.total_distance <
                                            fleetTotalDistance / vehicleReportCount
                                                ? element.total_distance
                                                : fleetTotalDistance / vehicleReportCount
                                        )
                                    ),
                                    total_distance_above_average: Number(
                                        i18nDistanceUnit(
                                            element.total_distance <
                                            fleetTotalDistance / vehicleReportCount
                                                ? 0
                                                : element.total_distance -
                                                  fleetTotalDistance / vehicleReportCount
                                        )
                                    ),
                                    total: Number(i18nDistanceUnit(element.total_distance)).toFixed(
                                        0
                                    ),
                                }))
                                .sort(
                                    (a, b) =>
                                        b.total_distance_below_average +
                                        b.total_distance_above_average -
                                        (a.total_distance_below_average +
                                            a.total_distance_above_average)
                                )}
                            bar={[
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.underAVG',
                                    })}`,
                                    barName: 'total_distance_below_average',
                                    barStackId: 'a',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.overAVG',
                                    })}`,
                                    barName: 'total_distance_above_average',
                                    barStackId: 'a',
                                    stroke: colors.bleu1F,
                                    fill: colors.bleu1F,
                                },
                            ]}
                            layout={'vertical'}
                            type={'distance'}
                            totalBar
                        />
                        <GeoHBarChart
                            loading={loadingDashboardReportSummary}
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.UtilisationTime.mode',
                            })}
                            dateRange={dateRange}
                            data={reportSummaryData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    total_utilisation_below_average: (element.total_utilisation_time <
                                    fleetTotalUtilisationTime / vehicleReportCount
                                        ? element.total_utilisation_time
                                        : fleetTotalUtilisationTime / vehicleReportCount
                                    ).toFixed(0),
                                    total_utilisation_above_average: (element.total_utilisation_time <
                                    fleetTotalUtilisationTime / vehicleReportCount
                                        ? 0
                                        : element.total_utilisation_time -
                                          fleetTotalUtilisationTime / vehicleReportCount
                                    ).toFixed(0),
                                    total: element.total_utilisation_time.toFixed(0),
                                }))
                                .sort(
                                    (a, b) =>
                                        b.total_utilisation_below_average +
                                        b.total_utilisation_above_average -
                                        (a.total_utilisation_below_average +
                                            a.total_utilisation_above_average)
                                )}
                            bar={[
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.underAVG',
                                    })} `,
                                    barName: 'total_utilisation_below_average',
                                    barStackId: 'a',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.overAVG',
                                    })} `,
                                    barName: 'total_utilisation_above_average',
                                    barStackId: 'a',
                                    stroke: colors.bleu1F,
                                    fill: colors.bleu1F,
                                },
                            ]}
                            layout={'vertical'}
                            type={'time'}
                            totalBar
                        />
                        <GeoHBarChart
                            loading={loadingDashboardReportSummary}
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Idling.mode',
                            })}
                            dateRange={dateRange}
                            data={reportSummaryData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    total_idling_below_average: (element.total_time_idle <
                                    fleetTotalIdling / vehicleReportCount
                                        ? element.total_time_idle
                                        : fleetTotalIdling / vehicleReportCount
                                    ).toFixed(0),
                                    total_idling_above_average: (element.total_time_idle <
                                    fleetTotalIdling / vehicleReportCount
                                        ? 0
                                        : element.total_time_idle -
                                          fleetTotalIdling / vehicleReportCount
                                    ).toFixed(0),
                                    total: element.total_time_idle.toFixed(0),
                                }))
                                .sort(
                                    (a, b) =>
                                        b.total_idling_below_average +
                                        b.total_idling_above_average -
                                        (a.total_idling_below_average +
                                            a.total_idling_above_average)
                                )}
                            bar={[
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.underAVG',
                                    })} `,
                                    barName: 'total_idling_below_average',
                                    barStackId: 'a',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: `${intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Distance.overAVG',
                                    })} `,
                                    barName: 'total_idling_above_average',
                                    barStackId: 'a',
                                    stroke: colors.pink,
                                    fill: colors.pink,
                                },
                            ]}
                            layout={'vertical'}
                            type={'time'}
                            totalBar
                        />
                        <GeoPieChart
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Personal.work.mode',
                            })}
                            dateRange={dateRange}
                            loading={loadingDashboardReportSummary}
                            data={[
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.mode.work',
                                    }),
                                    value: Number(i18nDistanceUnit(fleetTotalWork)),
                                    fill: colors.green61,
                                },
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.mode.personal',
                                    }),
                                    value: Number(i18nDistanceUnit(fleetTotalPersonal)),
                                    fill: colors.pink,
                                },
                            ]}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.mode.work',
                                    }),
                                    barName: 'total_work_mode',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.mode.personal',
                                    }),
                                    barName: 'total_personal_mode',
                                    stroke: colors.bleu1F,
                                    fill: colors.bleu1F,
                                },
                            ]}
                            dataExcel={reportSummaryData
                                .map((element, index) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    total_work_mode: Number(
                                        i18nDistanceUnit(element.total_work_mode)
                                    ),
                                    total_personal_mode: Number(
                                        i18nDistanceUnit(element.total_personal_mode)
                                    ),
                                }))
                                .sort(
                                    (a, b) =>
                                        b.total_work_mode +
                                        b.total_personal_mode -
                                        (a.total_work_mode + a.total_personal_mode)
                                )}
                            dataKey={'value'}
                            type={'distance'}
                        />
                        {/* Geofences */}
                        <GeoPieChart
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Geofences.mode',
                            })}
                            dateRange={dateRange}
                            description={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.Geofences.description',
                            })}
                            loading={loadingDashboardGeofenceSummary}
                            data={geofenceSummaryData.slice(0, 5).map(
                                (element, index) =>
                                    index < 5 && {
                                        name: element.geo_name,
                                        value: element.geo_count,
                                        fill: COLORS[index % COLORS.length],
                                    }
                            )}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.Geofences.visits',
                                    }),
                                    barName: 'geo_count',
                                    stroke: colors.green61,
                                    fill: colors.green61,
                                },
                            ]}
                            dataExcel={geofenceSummaryData}
                            dataKey={'value'}
                            excelKey={'geo_name'}
                        />
                        <GeoHBarChart
                            loading={loadingDashboardReportSummary}
                            title={intl.formatMessage({
                                id: 'views.Reports.SideMenu.Dashboard.OverSpeed.mode',
                            })}
                            dateRange={dateRange}
                            data={reportSummaryData.sort(
                                (a, b) =>
                                    b.total_over_speed_limit_number -
                                    a.total_over_speed_limit_number
                            )}
                            bar={[
                                {
                                    name: intl.formatMessage({
                                        id: 'views.Reports.SideMenu.Dashboard.OverSpeed.mode',
                                    }),
                                    barName: 'total_over_speed_limit_number',
                                    barStackId: 'a',
                                    stroke: colors.bleu1F,
                                    fill: colors.bleu1F,
                                },
                            ]}
                            layout={'vertical'}
                            type={'over_speed'}
                        />
                        <EngineErrorChart
                            data={vehiclesErrorCodes}
                            loading={loadingDashboardInstantSummary}
                            dateRange={dateRange}
                            bar={[
                                {
                                    name: 'suspect',
                                    barName: 'suspect',
                                },
                                {
                                    name: 'fmi',
                                    barName: 'fmi',
                                },
                                {
                                    name: 'occurence',
                                    barName: 'occurence',
                                },
                            ]}
                        />
                        {/* Regeneration */}
                        {regenerationSummaryData.length !== 0 && (
                            <GeoPieChart
                                title={intl.formatMessage({
                                    id: 'views.Reports.SideMenu.Dashboard.Regeneration.mode',
                                })}
                                dateRange={dateRange}
                                description={intl.formatMessage({
                                    id: 'views.Reports.SideMenu.Dashboard.Regeneration.description',
                                })}
                                loading={loadingDashboardRegenerationSummary}
                                data={regenerationSummaryData.slice(0, 5).map(
                                    (element, index) =>
                                        index < 5 && {
                                            name: element.vehicle_numb,
                                            value: element.count,
                                            fill: COLORS[index % COLORS.length],
                                        }
                                )}
                                bar={[
                                    {
                                        name: intl.formatMessage({
                                            id:
                                                'views.Reports.SideMenu.Dashboard.Regeneration.occurences',
                                        }),
                                        barName: 'count',
                                        stroke: colors.green61,
                                        fill: colors.green61,
                                    },
                                    {
                                        name: intl.formatMessage({
                                            id:
                                                'views.Reports.SideMenu.Dashboard.Regeneration.latestregentime',
                                        }),
                                        barName: 'latest_regen_datetime',
                                        stroke: colors.green61,
                                        fill: colors.green61,
                                    },
                                ]}
                                dataExcel={regenerationSummaryData.map((element) => ({
                                    vehicle_numb: element.vehicle_numb,
                                    latest_regen_datetime: convertToLocalTime(
                                        element.latest_regen_datetime
                                    ),
                                }))}
                                dataKey={'value'}
                                excelKey={'vehicle_numb'}
                            />
                        )}
                    </div>
                </div>,
            ];
        }
    };

    return (
        <ReportGeneratorContainer>
            <GenerateReportHeader
                report={report}
                title={intl.formatMessage({
                    id: 'views.Reports.SideMenu.Dashboard',
                })}
            />
            <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
            <ReportsInputDate
                startDate={moment(periodFrom)
                    //.utc()
                    .format('YYYY-MM-DD')}
                onChangeStartDate={handleOnChangePeriod('periodFrom')}
                endDate={moment(periodTo)
                    //.utc()
                    .format('YYYY-MM-DD')}
                onChangeEndDate={handleOnChangePeriod('periodTo')}
                errorEndDate={moment(periodTo)
                    //.utc()
                    .format('YYYY-MM-DD')}
                dateLimit={dateLimit}
                dateLimitPositionLeft={dateLimitPositionLeft}
            />
            {renderDashboardBody()}
        </ReportGeneratorContainer>
    );
};

const mapStateToProps: Object = createStructuredSelector({
    dashboardReportSummary: selectDashboardReportSummary(),
    loadingDashboardReportSummary: selectIsLoadingDashboardReportSummary(),
    dashboardRealtimesSummary: selectDashboardRealtimesSummary(),
    loadingDashboardRealtimesSummary: selectIsLoadingDashboardRealtimesSummary(),
    dashboardGeofenceSummary: selectDashboardGeofenceSummary(),
    dashboardRegenerationSummary: selectDashboardRegenerationSummary(),
    loadingDashboardGeofenceSummary: selectIsLoadingDashboardGeofenceSummary(),
    loadingDashboardRegenerationSummary: selectIsLoadingDashboardRegenerationSummary(),
    dashboardInstantSummary: selectDashboardInstantSummary(),
    loadingDashboardInstantSummary: selectIsLoadingDashboardInstantSummary(),
    unit: selectUnit(),
    user: selectUser(),
    vehicles: selectVehicles(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            generateDashboardReportSummary,
            generateDashboardRealtimesSummary,
            generateDashboardGeofenceSummary,
            generateDashboardRegenerationSummary,
            generateDashboardInstantSummary,
            i18nDistanceUnit,
            changeUnit,
            i18nDistanceSymbol,
            getHistoryModification,
            getHistoryModificationByBranchId,
            getVehicles,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
