// @flow

export const REPORT = {
    sideMenuItems: [
        {
            translationId: 'views.Reports.SideMenu.Dashboard',
            url: '/reports/dashboards',
        },
        {
            translationId: 'views.Reports.SideMenu.Deplacements',
            url: '/reports/speeding',
        },
        {
            translationId: 'views.Reports.SideMenu.Move',
            url: '/reports/travels',
        },
        {
            translationId: 'views.Reports.SideMenu.dynamicMove',
            url: '/reports/travels-dynamic',
        },
        {
            translationId: 'views.Reports.SideMenu.Economie',
            url: '/reports/fuelsaving',
        },
        {
            translationId: 'views.Reports.SideMenu.Geofencing',
            url: '/reports/geofences',
        },
        {
            translationId: 'views.Reports.SideMenu.Inactivite',
            // url: '/reports/displacement',
        },
        {
            translationId: 'views.Reports.SideMenu.RapportDeConduite',
            // url: '/reports/displacement',
        },
        {
            translationId: 'views.Reports.SideMenu.RapportPeronalise',
            // url: '/reports/displacement',
        },
        {
            translationId: 'views.Reports.SideMenu.Temperature',
            url: '/reports/temp-graph',
        },
        {
            translationId: 'views.Reports.SideMenu.temperatureReport',
            url: '/reports/temp-monitoring',
        },
        {
            translationId: 'views.Reports.SideMenu.automaticReports',
            url: '/reports/automatic-reports',
        },
    ],
};

export const OUTPUT_FORMATS = {
    pdf: 'pdf',
    csv: 'csv',
    xls: 'xlsx',
    page: 'display',
};

export const OUTPUT_TYPE = {
    vehicle: 0,
    driver: 1,
    geofences: 2,
    regeneration: 3,
};

export const REPORT_TYPES = {
    detailed: 'detailed',
    summary: 'summary',
};

export const PRESET_PERIODS = {
    today: 'today',
    yesterday: 'yesterday',
    lastWeek: 'lastweek',
    thisMonth: 'thismonth',
    lastMonth: 'lastmonth',
    thisWeek: 'thisweek',
    custom: 'custom',
};
