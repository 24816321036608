import { fromJS } from 'immutable';
import {
    FETCH_AUTOMATIC_REPORT_LIST_REQUEST,
    FETCH_AUTOMATIC_REPORT_LIST_SUCCESS,
    FETCH_AUTOMATIC_REPORT_LIST_FAILURE,
    DELETE_AUTOMATIC_REPORT_ITEM_REQUEST,
    DELETE_AUTOMATIC_REPORT_ITEM_SUCCESS,
    DELETE_AUTOMATIC_REPORT_ITEM_FAILURE,
    POST_AUTOMATIC_REPORT_ITEM_REQUEST,
    POST_AUTOMATIC_REPORT_ITEM_SUCCESS,
    POST_AUTOMATIC_REPORT_ITEM_FAILURE,
} from './actions';

const initialState = fromJS({
    loading: false,
    automaticReportList: null,
    error: null,
    loadingDeleteAutomaticReportItem: false,
    dataDeleteAutomaticReportItem: null,
    errorDeleteAutomaticReportItem: null,
    loadingPostAutomaticReportItem: false,
    dataPostAutomaticReportItem: null,
    errorPostAutomaticReportItem: null,
});

const AutomaticReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUTOMATIC_REPORT_LIST_REQUEST:
            return state.set('loading', true);
        case FETCH_AUTOMATIC_REPORT_LIST_SUCCESS:
            return state.set('loading', false).set('automaticReportList', action.payload);
        case FETCH_AUTOMATIC_REPORT_LIST_FAILURE:
            return state.set('loading', false).set('error', action.error);
        case DELETE_AUTOMATIC_REPORT_ITEM_REQUEST:
            return state.set('loadingDeleteAutomaticReportItem', true);
        case DELETE_AUTOMATIC_REPORT_ITEM_SUCCESS:
            return state
                .set('loadingDeleteAutomaticReportItem', false)
                .set('dataDeleteAutomaticReportItem', action.payload);
        case DELETE_AUTOMATIC_REPORT_ITEM_FAILURE:
            return state
                .set('loadingDeleteAutomaticReportItem', false)
                .set('errorDeleteAutomaticReportItem', action.error);
        case POST_AUTOMATIC_REPORT_ITEM_REQUEST:
            return state.set('loadingPostAutomaticReportItem', true);
        case POST_AUTOMATIC_REPORT_ITEM_SUCCESS:
            return state
                .set('loadingPostAutomaticReportItem', false)
                .set('dataPostAutomaticReportItem', action.payload);
        case POST_AUTOMATIC_REPORT_ITEM_FAILURE:
            return state
                .set('loadingPostAutomaticReportItem', false)
                .set('errorPostAutomaticReportItem', action.error);
        default:
            return state;
    }
};

export default AutomaticReportReducer;
